import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isLoggedIn } from '../utils'

const PrivateRoute = ({ render, ...rest }) => (
  <Route {...rest} render={() => (isLoggedIn() ? render() : <Redirect to="/auth/login" />)} />
)
PrivateRoute.propTypes = {
  render: PropTypes.func.isRequired,
}

export default PrivateRoute
