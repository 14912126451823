import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InboxOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Upload, notification } from 'antd'
import { uniqueId } from 'lodash'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { client } from '../../../services/graphql'
import { UPLOAD_FILE_MUTATION, DELETE_FILE_MUTATION } from '../../../services/graphql/mutations'
import { GET_PRESIGNED_URL } from '../../../services/graphql/queries'
import { delay } from '../../../utils'

const { Dragger } = Upload

const Uploader = ({
  multiple,
  name,
  title,
  description,
  accept,
  currentFileList,
  attribute,
  resourceId,
  resourceClass,
  parentId,
  parentClass,
  multipleFiles,
}) => {
  const { t } = useTranslation('uploader')
  const initialState = currentFileList
    ? currentFileList.map(f => ({
        uid: Number(f.resourceId) || uniqueId(),
        name: f.filename,
        status: 'done',
        url: f.url,
      }))
    : []
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION, {
    onCompleted: () => notification.success({ message: t('success') }),
  })
  const [removeFile] = useMutation(DELETE_FILE_MUTATION, {
    onCompleted: data =>
      data?.deleteFile
        ? notification.success({ message: t('deleted') })
        : notification.error({ message: t('error') }),
    onError: err => notification.error({ message: err.toString() }),
  })
  const [fileList, setFileList] = useStateWithCallbackLazy(initialState)
  const [headers, setHeaders] = useState()
  const [s3Urls, setS3Urls] = useState({})
  const [variables, setVariables] = useState()
  useEffect(() => {
    if (resourceId && !parentId && resourceClass && !parentClass) {
      setVariables({
        attribute,
        resourceId: Number(resourceId),
        resourceClass,
      })
    }
    if (!resourceId && parentId && resourceClass && parentClass) {
      setVariables({
        attribute,
        parentId,
        resourceClass,
        parentClass,
      })
    }
  }, [attribute, parentClass, parentId, resourceClass, resourceId])

  const handleRemove = file => {
    const sureToDelete = window.confirm(
      attribute === 'image'
        ? t('tableEssentials:sureToDeleteImage')
        : t('tableEssentials:sureToDelete')
    )
    if (!sureToDelete) return false

    if (variables) {
      if (attribute === 'document' && !multiple) {
        removeFile({
          variables,
        })
      } else {
        removeFile({
          variables: {
            resourceId: Number(file.uid),
            resourceClass: variables.resourceClass,
          },
        })
      }
    } else {
      notification.success({ message: t('error') })
      return false
    }
    return true
  }

  const handleFileChanged = ({ file }) => {
    if (!multipleFiles) {
      if (file.status === 'removed') {
        setFileList([])
      } else if (file.status === 'done') {
        const newFile = {
          ...file,
          s3Url: s3Urls?.[file.uid],
        }
        setFileList([newFile])
      }
    } else if (file.status === 'removed') {
      setFileList(fileList.filter(f => f.status !== 'removed'))
    } else if (file.status === 'done') {
      const newFile = {
        ...file,
        s3Url: s3Urls?.[file.uid],
      }
      setFileList(fl => {
        let newFileList = [...fl]
        const oldFileIndex = fl.findIndex(f => f.uid === newFile.uid)
        if (oldFileIndex >= 0) {
          newFileList[oldFileIndex] = newFile
        } else {
          newFileList = [...newFileList, newFile]
        }
        return newFileList
      })
    }

    if (variables && file.status === 'uploading') {
      setFileList(fl => (multipleFiles ? [...fl, file] : [file]))
    }
  }

  const handleBeforeUpload = async file => {
    // Headers are required by Amazon S3
    const { name: filename, type } = file

    setHeaders({
      'x-amz-acl': 'public-read',
      'Content-Type': type,
    })

    // Fetches the Signed URL from S3 bucket
    // Prepend with the ID to make the file name unique
    const {
      data: { getPresignedS3Url },
    } = await client.query({
      query: GET_PRESIGNED_URL,
      variables: {
        filename,
      },
    })

    setS3Urls(urls => ({ ...urls, [file.uid]: getPresignedS3Url }))
  }

  // Custom xhr upload method
  const handleUpload = async res => {
    const { onSuccess, onError, file } = res
    const xhr = new XMLHttpRequest()
    const newXhr = await delay(1000).then(() => {
      // S3 requires PUT method!
      xhr.open('PUT', s3Urls[file.uid])
      xhr.onreadystatechange = async () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            await onSuccess(null, file)
            uploadFile({
              variables: {
                ...variables,
                filename: file?.name,
              },
            })
          } else {
            notification.success({ message: t('success') })
            await onError(xhr.responseText, xhr.response, file)
          }
        }
      }
      return xhr
    })
    newXhr.send(file)
  }

  return (
    <Dragger
      name={name}
      multiple={multiple}
      headers={headers}
      // action={s3Urls}
      fileList={fileList}
      customRequest={handleUpload}
      beforeUpload={handleBeforeUpload}
      onChange={handleFileChanged}
      onRemove={handleRemove}
      accept={accept}
      listType={attribute === 'image' || attribute === 'header_image' ? 'picture-card' : 'text'}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{title}</p>
      <p className="ant-upload-hint">{description}</p>
    </Dragger>
  )
}

Uploader.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  accept: PropTypes.string,
  currentFileList: PropTypes.array,
  attribute: PropTypes.PropTypes.oneOf(['document', 'image', 'excel']).isRequired,
  resourceId: PropTypes.number,
  resourceClass: PropTypes.string,
  parentId: PropTypes.number,
  parentClass: PropTypes.string,
  multipleFiles: PropTypes.bool,
}

Uploader.defaultProps = {
  title: 'Click or drag file to this area to upload',
  description:
    ' Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files ',
  multiple: false,
  accept: null,
  currentFileList: [],
  resourceId: null,
  resourceClass: null,
  parentId: null,
  parentClass: null,
  multipleFiles: true,
}

export default Uploader
