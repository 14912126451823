import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import Layout from '../layouts'
import PrivateRoute from './PrivateRoute'
import routes from './routes'

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => (
  <ConnectedRouter history={history}>
    <Layout>
      <Route
        render={state => {
          const { location } = state
          return (
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                appear
                classNames={routerAnimation}
                timeout={routerAnimation === 'none' ? 0 : 300}
              >
                <Switch location={location}>
                  {/* new routes */}
                  <Route exact path="/" render={() => <Redirect to="/dashboards/activity" />} />
                  {routes.map(({ path, Component, exact, secured }) =>
                    secured ? (
                      <PrivateRoute
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => (
                          <div className={routerAnimation}>
                            <Suspense fallback={null}>
                              <Component />
                            </Suspense>
                          </div>
                        )}
                      />
                    ) : (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={props => (
                          <div className={routerAnimation}>
                            <Suspense fallback={null}>
                              <Component {...props} />
                            </Suspense>
                          </div>
                        )}
                      />
                    )
                  )}
                  <Redirect to="/auth/404" />
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          )
        }}
      />
    </Layout>
  </ConnectedRouter>
)

Router.propTypes = {
  history: PropTypes.object.isRequired,
  routerAnimation: PropTypes.string.isRequired,
}

export default connect(mapStateToProps)(Router)
