export const locales = {
  de: {
    antd: 'de_DE',
    moment: 'de',
    name: 'de',
  },
  en: {
    antd: 'en_US',
    moment: 'en-gb',
    name: 'en',
  },
}

const loadLocale = async locale =>
  locales[locale]
    ? {
        antd: await import(`antd/es/locale/${locales[locale].antd}.js`),
        moment: await import(`moment/locale/${locales[locale].moment}`),
      }
    : loadLocale('en')

export default loadLocale
