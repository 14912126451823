import React from 'react'
import PropTypes from 'prop-types'

const FormText = ({ id, value }) => <span id={id}>{value}</span>

FormText.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
}

FormText.defaultProps = {
  value: null,
}

export default FormText
