import React from 'react'
import AdminMenu from './AdminMenu'
import style from './style.module.scss'

const TopBar = () => (
  <div className={style.topbar}>
    <div className="w-100" id="topBarFilterContainer" />
    <div className="ml-auto mr-4 d-none d-sm-block">
      {/* <Suspense fallback={<Spin />}>
          <LanguageSwitcher />
        </Suspense>
      </div>
      <div className=""> */}
      <AdminMenu />
    </div>
  </div>
)

export default TopBar
