import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const FilterPortal = ({ children }) => {
  const container = document.getElementById('topBarFilterContainer')
  return container && ReactDOM.createPortal(children, container)
}
FilterPortal.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FilterPortal
