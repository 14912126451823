import React from 'react'
import { AnnotationXYThreshold } from 'react-annotation'
import LazyLoad from 'react-lazyload'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import { OrdinalFrame } from 'semiotic'
import CenteredSpinner from '../../CenteredSpinner'
import dummyData from './data'

const StackedBarChartSemiotic = ({
  title,
  data,
  height,
  width,
  horizontal,
  pixelColumnWidth,
  maxValue,
  colors,
  rAccessor,
  currency,
  annotated,
}) => {
  const calculatedMaxValue = data?.reduce((def, a) => a[rAccessor] + def, 0)
  const frameProps = {
    data,
    rExtent: [0, maxValue],

    /* --- Size --- */
    size: [width, height],
    oPadding: 10,

    /* --- Layout --- */
    type: 'bar',
    projection: horizontal ? 'horizontal' : 'vertical',
    margin: horizontal ? 0 : { top: 10, right: 0, bottom: 0, left: 0 },

    /* --- Process --- */
    oAccessor: title,
    // oAccessor: 'resourceTitle',
    rAccessor,

    /* --- Customize --- */
    style: d => {
      let color = '#ea638c'
      if (d.resourceTitle) {
        color = colors[d.resourceTitle.toLowerCase()]
      } else if (color[d.label]) {
        color = colors[d.label.toLowerCase()]
      } else if (color[d.renderKey]) {
        color = colors[d.renderKey]
      }
      return {
        fill: color,
      }
    },
    // title,
    pixelColumnWidth,
    annotations: annotated
      ? [
          {
            type: AnnotationXYThreshold,
            note: {
              label: new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
                thousandSeparator: '.',
                style: currency ? 'currency' : 'decimal',
                currency: 'EUR',
                currencyDisplay: 'symbol',
              }).format(calculatedMaxValue),
              lineType: null,
              orientation: 'topBottom',
              align: 'middle',
              color: 'black',
            },
            dy: -5,
            color: 'transparent',
            coordinates: [
              {
                [rAccessor]: calculatedMaxValue,
              },
            ],
            adjustedPosition: {
              x: 100,
              y: 200,
            },
            // -data?.reduce((def, a) => a[rAccessor] + def, 0),
          },
        ]
      : null,

    /* --- Interact --- */
    pieceHoverAnnotation: true,
    customClickBehavior: e => console.warn('e', e),
    tooltipContent: item => (
      <div className="custom-tooltip">
        <NumberFormat
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          value={item.value}
          suffix={currency ? ' €' : null}
        />
      </div>
    ),
    oLabel: true,
  }
  if (!data) return null
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      <LazyLoad placeholder={<CenteredSpinner width={width} height={height} />} offset={100}>
        <OrdinalFrame {...frameProps} />
      </LazyLoad>
    </div>
  )
}

StackedBarChartSemiotic.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  horizontal: PropTypes.bool,
  currency: PropTypes.bool,
  annotated: PropTypes.bool,
  pixelColumnWidth: PropTypes.number,
  maxValue: PropTypes.number,
  rAccessor: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.object,
}

StackedBarChartSemiotic.defaultProps = {
  title: '',
  data: [dummyData[0]],
  height: 250,
  width: 250,
  horizontal: false,
  currency: false,
  colors: {
    seasonal: '#59c594',
    promotion: '#52bbea',
    permanent: '#87255b',
    sonstige: '#ea638c',
  },
  rAccessor: ['amount'],
  pixelColumnWidth: 30,
  maxValue: 30,
  annotated: false,
}

export default StackedBarChartSemiotic
