import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import reduxStore from 'store'
import config from '../../config'
import {
  LOGIN_MUTATION,
  REGISTER_MUTATION,
  FORGOT_PASSWORD_MUTATION,
  RESET_PASSWORD_MUTATION,
  VALIDATE_TOKEN_MUTATION,
} from './mutations'
import { COMPANY_QUERY } from './queries'

const AUTH_TOKEN = 'AUTH_TOKEN'

const httpLink = new HttpLink({ uri: config.apollo.networkInterface })

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  const authorizationHeader = token
  if (authorizationHeader) {
    operation.setContext({
      headers: {
        Authorization: authorizationHeader,
      },
    })
  }
  return forward(operation)
})

// After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.
const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext()
    const {
      response: { headers },
    } = context

    if (headers) {
      const refreshToken = headers.get('authorization')
      if (refreshToken) {
        localStorage.setItem(AUTH_TOKEN, refreshToken)
      }
    }

    return response
  })
)

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )

  if (networkError) console.warn(`[Network error]: ${networkError}`)
  return forward(operation)
})

export const client = new ApolloClient({
  link: from([middlewareAuthLink, afterwareLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
})

export const resetPassword = async (token, password, passwordConfirmation) => {
  if (token && password && passwordConfirmation) {
    return client
      .mutate({
        mutation: RESET_PASSWORD_MUTATION,
        variables: {
          resetPasswordToken: token,
          password,
          passwordConfirmation,
        },
      })
      .then(data => {
        if (data && data.data && data.data.resetPassword) {
          const { success } = data.data.resetPassword
          return success
        }
        return false
      })
      .catch(err => console.error('err', err))
  }
  return false
}

export const forgotPassword = async email => {
  if (email) {
    return client
      .mutate({
        mutation: FORGOT_PASSWORD_MUTATION,
        variables: { email },
      })
      .then(data => {
        if (data && data.data && data.data.forgotPassword) {
          const { success } = data.data.forgotPassword
          return success
        }
        return false
      })
      .catch(err => console.error('err', err))
  }
  return false
}

export const login = async (email, password, rememberMe = false) => {
  if (email && password) {
    return client
      .mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          email,
          password,
          rememberMe,
        },
      })
      .then(data => {
        if (data && data.data && data.data.signIn) {
          const { user } = data.data.signIn

          reduxStore.set('user', user)
          return user
        }
        return false
      })
      .catch(err => console.error('err', err))
  }
  return false
}

export const register = async (email, password, passwordConfirmation) => {
  if (email && password && passwordConfirmation) {
    return client
      .mutate({
        mutation: REGISTER_MUTATION,
        variables: {
          email,
          password,
          passwordConfirmation,
        },
      })
      .then(data => {
        if (data && data.data && data.data.signUp) {
          const { user } = data.data.signUp

          reduxStore.set('user', user)
          return user
        }
        return false
      })
      .catch(err => console.error('err', err))
  }
  return false
}

export const currentAccount = async () =>
  client.query(COMPANY_QUERY).catch(err => console.error('err', err))

export const currentUser = async () =>
  client
    .mutate({ mutation: VALIDATE_TOKEN_MUTATION })
    .then(data => {
      if (data.data && data.data.validateToken.success) {
        const { user } = data.data.validateToken
        return user
      }
      return false
    })
    .catch(err => console.error('err', err))

export const logout = () => {
  reduxStore.remove('user')
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.clear()
  client.clearStore()
  client.cache.reset()
  return true
}
