import { notification } from 'antd'
import errMessages from './errorMessages'

const ErrorMessageHandler = (message, t = m => m) => {
  if (message && message.match('\n')) {
    const messages = message.split('\n')
    messages.map(singleMessage =>
      notification.error({
        message: t('common:error'),
        description: getErrorMessage(singleMessage, t),
      })
    )
  } else {
    notification.error({
      message: t('common:error'),
      description: getErrorMessage(message, t),
    })
  }
}

const getErrorMessage = (message, t) =>
  errMessages.find(item => item.name === message)
    ? t(`errorMessages:${errMessages.find(item => item.name === message).value}`)
    : message

export default ErrorMessageHandler
