import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Button, Card, Form, Input, InputNumber, notification, Switch } from 'antd'
import { uniqueId } from 'lodash'
import moment from 'moment'
import { StyledTag, StyledMutationLink } from '../..'
import FormDatePicker from '../../atoms/FormDatePicker'
import MarkdownTextArea from '../../atoms/MarkdownTextArea'
import { Uploader } from '../../molecules'
import CustomSelect from './CustomSelect'

const EditableFreeForm = (
  { data, elements, updateMutation, title, noSubmit, multiCols, css },
  ...restProps
) => {
  const updateData = values => {
    const dateFields = elements.filter(elem => elem.type === 'date').map(dateField => dateField.id)
    const dateValues = {}
    dateFields.forEach(field => {
      dateValues[field] = values[field] ? moment(values[field]).format('YYYY-MM-DD') : undefined
    })

    updateMutation({
      variables: {
        id: data.id,
        attributes: { ...values, ...dateValues },
      },
    })
  }
  const { t } = useTranslation('dataManagement')
  const getLabel = label => {
    if (typeof label === 'object') {
      return (
        <div>
          <div className="font-weight-bolder">{label.title}</div>
          <div className="font-weight-lighter">{label.subtitle}</div>
        </div>
      )
    }
    return <div className="font-weight-bolder">{label}</div>
  }
  const renderInput = element => (
    <Form.Item key={element.id} name={element.id} label={getLabel(element.label)}>
      <Input
        placeholder={element.placeholder ? element.placeholder : element.label}
        disabled={element.disabled}
      />
    </Form.Item>
  )
  const renderNumber = (element, variant) => {
    let ReturnInput = <></>
    switch (variant) {
      case 'currency':
        ReturnInput = (
          <InputNumber
            placeholder={getLabel(element.label)}
            disabled={element.disabled}
            step={0.01}
            precision={2}
            decimalSeparator=","
          />
        )
        break

      default:
        ReturnInput = <InputNumber placeholder={element.label} disabled={element.disabled} />
        break
    }
    return (
      <Form.Item
        key={element.id}
        name={element.id}
        label={getLabel(element.label)}
        rules={element.rules}
      >
        {ReturnInput}
      </Form.Item>
    )
  }

  const renderSwitch = element => (
    <Form.Item
      key={element.id}
      name={element.id}
      label={getLabel(element.label)}
      valuePropName="checked"
    >
      <Switch onChange={element.onChange}>{element.description}</Switch>
    </Form.Item>
  )

  const normFile = e => {
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const renderUploader = element => (
    <Form.Item
      key={element.id}
      name={element.id}
      label={getLabel(element.label)}
      valuePropName="currentFile"
      getValueFromEvent={normFile}
    >
      <Uploader
        title={element.title}
        description={element.description}
        name={element.id}
        resourceId={data.id}
        accept={element.accept}
        attribute={element.attribute}
        resourceClass={element.resourceClass}
        {...restProps}
      />
    </Form.Item>
  )

  const renderDropdown = element => (
    <CustomSelect
      dropdownMode={element.dropdownMode}
      dropdownTitleName={element.dropdownTitleName}
      id={element.id}
      query={element.query}
      queryVariables={element.queryVariables}
      label={getLabel(element.label)}
      lazyLoad={element.lazyLoad}
      data={data}
    />
  )

  const renderTextArea = element => (
    <Form.Item key={element.id} name={element.id} label={getLabel(element.label)}>
      <Input.TextArea
        placeholder={element.placeholder ? element.placeholder : element.label}
        onChange={element.onChange}
      />
    </Form.Item>
  )

  const renderMarkdownTextArea = element => <MarkdownTextArea element={element} data={data} />

  const renderDatePicker = element => (
    <Form.Item key={element.id} name={element.id} label={getLabel(element.label)}>
      <FormDatePicker />
    </Form.Item>
  )
  const renderMutationLink = element => (
    <div key={element.id} name={element.id}>
      <StyledMutationLink
        label={getLabel(element.label)}
        onClick={element.onClick}
        button={element.button}
      />
    </div>
  )

  if (!data) return <div>{t('common:loading')}</div>

  const Text = ({ value }) => {
    if (typeof value === 'boolean') {
      return <span className="ant-form-text">{value ? t('yes') : t('no')}</span>
    }
    if (!value) return null
    if (typeof value !== 'string') {
      const returnValue = []
      value.map(val => {
        if (typeof val === 'string') returnValue.push(val)
        if (typeof val === 'object') returnValue.push(val.name)
        return true
      })
      return <span className="ant-form-text">{returnValue.join(',')}</span>
    }

    return <span className="ant-form-text">{value}</span>
  }
  Text.propTypes = {
    value: PropTypes.any,
  }
  Text.defaultProps = {
    value: '',
  }

  const renderPlainText = element => (
    <Form.Item
      key={element.id}
      name={element.id}
      label={<strong>{getLabel(element.label)}</strong>}
    >
      <Text />
    </Form.Item>
  )

  const CreateStyledText = ({ value, label }) => {
    if (!value) return null
    if (value !== 'string') {
      return (
        <div className="card">
          <div className="card-header">
            <div className="cui__utils__heading mb-0">
              <strong>{label}</strong>
            </div>
          </div>
          <div className="card-body">
            <div className="kit__utils__table">
              {value.map(val => (
                <>
                  {val.label ? <strong>{val.label}</strong> : null}
                  {val.text ? <p>{val.text}</p> : null}
                </>
              ))}
            </div>
          </div>
        </div>
      )
    }
    return <span className="ant-form-text">{value}</span>
  }
  CreateStyledText.propTypes = {
    value: PropTypes.any,
    label: PropTypes.any,
  }
  CreateStyledText.defaultProps = {
    value: '',
    label: '',
  }
  const renderStyledText = element => (
    <Form.Item key={element.id} name={element.id}>
      <CreateStyledText label={getLabel(element.label)} />
    </Form.Item>
  )
  const renderTag = element => (
    <Form.Item
      key={element.id}
      name={element.id}
      label={<strong>{getLabel(element.label)}</strong>}
    >
      <StyledTag tags={element.tags} />
    </Form.Item>
  )

  const createCols = cols =>
    cols.map(col => {
      if (col.render) {
        return (
          <div className="d-flex flex-row">
            <Form.Item key={col.id} name={col.id} label={<strong>{getLabel(col.label)}</strong>}>
              {col.render(data[col.id])}
            </Form.Item>
          </div>
        )
      }
      return <div className="d-flex flex-row">{getElement(col)}</div>
    })
  const renderGroup = element => (
    <>
      <div className="ant-card-head-wrapper">
        <div className="ant-card-head-title">{getLabel(element.label)}</div>
      </div>
      <p>{element.subtitle}</p>
      <div name="test" className="d-flex flex-row">
        {element.groupItems.map(item => (
          <div className="pr-2">{getElement(item)}</div>
        ))}
      </div>
    </>
  )
  const getElement = element => {
    if (element.type === 'input') {
      return renderInput(element)
    }
    if (element.type === 'switch') {
      return renderSwitch(element)
    }
    if (element.type === 'uploader') {
      return renderUploader(element)
    }
    if (element.type === 'textarea') {
      return renderTextArea(element)
    }
    if (element.type === 'markdownTextArea') {
      return renderMarkdownTextArea(element)
    }
    if (element.type === 'dropdown') {
      return renderDropdown(element)
    }
    if (element.type === 'styledText') {
      return renderStyledText(element)
    }
    if (element.type === 'text') {
      return renderPlainText(element)
    }
    if (element.type === 'tag') {
      return renderTag(element)
    }
    if (element.type === 'number') {
      return renderNumber(element)
    }
    if (element.type === 'currency') {
      return renderNumber(element, 'currency')
    }
    if (element.type === 'date') {
      return renderDatePicker(element)
    }
    if (element.type === 'mutationLink') {
      return renderMutationLink(element)
    }
    if (element.type === 'group') {
      return renderGroup(element)
    }
    return null
  }
  if (!data) return <div>Loading...</div>
  return (
    <Card className={css} title={title || t('informations.title')}>
      <Form
        name="form"
        initialValues={data}
        onFinish={updateData}
        onFinishFailed={() => {
          notification.error({
            message: t('error'),
          })
        }}
        layout="vertical"
        className="login-form"
      >
        {multiCols ? (
          <div className="d-flex flex-column flex-md-row">
            {elements.map(element => (
              <div key={element.id} className="d-flex flex-column flex-fill">
                {createCols(element)}
              </div>
            ))}
          </div>
        ) : (
          elements.map(element => (
            <Fragment key={element.id}>
              {element.render ? (
                <Form.Item
                  key={element.id}
                  name={element.id}
                  label={<strong>{getLabel(element.label)}</strong>}
                >
                  {element.render(data[element.id])}
                </Form.Item>
              ) : (
                getElement(element)
              )}
              {element.suffix ? <Form.Item key={uniqueId()}>{element.suffix}</Form.Item> : null}
            </Fragment>
          ))
        )}
        {noSubmit ? null : (
          <Button style={{ width: 200 }} type="primary" htmlType="submit" className="mr-3">
            {t('submit')}
          </Button>
        )}
      </Form>
    </Card>
  )
}
EditableFreeForm.propTypes = {
  css: PropTypes.string,
  data: PropTypes.object,
  noSubmit: PropTypes.bool,
  multiCols: PropTypes.bool,
  title: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      type: PropTypes.string.isRequired,
      render: PropTypes.func,
      suffix: PropTypes.string,
      button: PropTypes.bool,
      rules: PropTypes.array,
    }).isRequired
  ).isRequired,
  updateMutation: PropTypes.func.isRequired,
}
EditableFreeForm.defaultProps = {
  css: '',
  data: null,
  noSubmit: false,
  multiCols: false,
  title: '',
}

export default EditableFreeForm
