import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown as AntDropdown, Menu } from 'antd'

const Dropdown = ({
  mode,
  value,
  values,
  children,
  onChange,
  title,
  titlePropName,
  trigger,
  valuePropName,
  scrollable,
}) => {
  const [visible, setVisible] = useState(false)
  const onClick = item => {
    let filterProp = item.key
    if (valuePropName !== 'key') {
      filterProp = item.item.props[valuePropName]
    }
    if (value.find(v => v === filterProp)) {
      onChange(value.filter(v => v !== filterProp))
    } else {
      onChange([...value, filterProp])
    }
  }
  const handleVisibleChange = shouldBeVisible => {
    setVisible(shouldBeVisible)
  }
  const menu = (
    <Menu
      style={scrollable ? { maxHeight: '300px', overflowY: 'scroll' } : null}
      multiple={mode === 'multiple'}
      onClick={onClick}
      selectedKeys={value}
    >
      {children ||
        values?.map(item => (
          <Menu.Item key={item.key} value={item[valuePropName]}>
            {item[titlePropName]}
          </Menu.Item>
        ))}
    </Menu>
  )
  return (
    <AntDropdown
      overlay={menu}
      trigger={trigger}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button onClick={() => setVisible(!visible)}>
        {title}
        <DownOutlined />
      </Button>
    </AntDropdown>
  )
}
Dropdown.propTypes = {
  mode: PropTypes.oneOf(['multiple']),
  value: PropTypes.oneOfType([PropTypes.array]).isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titlePropName: PropTypes.string,
  valuePropName: PropTypes.string,
  children: PropTypes.node,
  trigger: PropTypes.array,
  scrollable: PropTypes.bool,
}
Dropdown.defaultProps = {
  trigger: ['click'],
  titlePropName: 'title',
  mode: 'multiple',
  valuePropName: 'value',
  children: null,
  scrollable: false,
}

export default Dropdown
