export default async function getMenuData() {
  return [
    // Dashboards
    {
      category: true,
      title: 'Dashboards',
      key: 'dashboards',

      icon: 'fas fa-tachometer-alt',
      children: [
        {
          title: 'Activity',
          key: 'luxDashboardsActivity',
          icon: 'fa fa-chart-pie',
          url: '/dashboards/activity',
        },
        {
          title: 'Campaigns',
          key: 'luxDashboardsCampaigns',
          icon: 'fas fa-copyright',
          url: '/dashboards/campaigns',
        },
        {
          title: 'Segmentation',
          key: 'luxDashboardsSegmentation',
          icon: 'fas fa-layer-group',
          url: '/dashboards/segmentation',
        },
      ],
    },
    // Reports
    {
      category: true,
      title: 'Reports',
      key: 'reports',
      icon: 'fas fa-chart-line',
      children: [
        {
          title: 'Assets',
          key: 'reportsAssets',
          icon: 'fa fa-glasses',
          url: '/reports/assets',
        },
        {
          title: 'Sales Reps',
          key: 'luxReportsSalesReps',
          icon: 'fas fa-comment-dollar',
          url: '/reports/sales-reps',
        },
        {
          title: 'Customers',
          key: 'luxReportsCustomers',
          icon: 'fas fa-users',
          url: '/reports/customers',
        },
      ],
    },

    // Administration
    {
      category: true,
      title: 'Administration',
      key: 'administration',
      icon: 'fas fa-user-lock',
      adminOnly: true,
      children: [
        {
          title: 'Asset management',
          key: 'assetManagement',
          icon: 'fa fa-glasses',
          url: '/asset-settings/asset-management',
          adminOnly: true,
        },
        {
          title: 'Customer management',
          key: 'customerManagement',
          icon: 'fas fa-users',
          url: '/customers/customers',
          adminOnly: true,
        },
        {
          title: 'User management',
          key: 'adminUserManagement',
          icon: 'fas fa-user-cog',
          url: '/administration/user-management',
          adminOnly: true,
        },
        {
          title: 'Partner programs',
          key: 'adminPartnerPrograms',
          icon: 'fas fa-user-friends',
          url: '/administration/partner-programs',
          adminOnly: true,
        },
      ],
    },

    // Orders
    {
      category: true,
      title: 'Orders',
      key: 'orders',
      icon: 'fas fa-box-open',
      adminOnly: true,
      children: [
        {
          title: 'Orders',
          key: 'orders',
          icon: 'fas fa-cubes',
          url: '/orders/orders',
          adminOnly: true,
        },
        {
          title: 'Today´s orders',
          key: 'todaysOrders',
          icon: 'fas fa-shipping-fast',
          url: '/orders/todays-export',
          adminOnly: true,
        },
        {
          title: 'Items on hold',
          key: 'itemsOnHold',
          icon: 'fas fa-exclamation-circle',
          url: '/orders/items-on-hold',
          adminOnly: true,
        },
      ],
    },

    // Settings
    {
      category: true,
      title: 'Settings',
      key: 'settings',
      icon: 'fas fa-tools',
      adminOnly: true,
      children: [
        {
          title: 'Logistic centers',
          key: 'logisticCenters',
          icon: 'fa fa-warehouse',
          url: '/asset-settings/logistic-centers',
          adminOnly: true,
        },
        {
          title: 'Merge customers',
          key: 'mergeCustomers',
          icon: 'fas fa-user-check',
          url: '/customers/merge-customers',
          adminOnly: true,
        },
        {
          title: 'Brands',
          key: 'adminBrands',
          icon: 'fa fa-shapes',
          url: '/administration/brands',
          adminOnly: true,
        },
        {
          title: 'Brand lines',
          key: 'adminBrandLines',
          icon: 'fa fa-project-diagram',
          url: '/administration/brandlines',
          adminOnly: true,
        },
        {
          title: 'Segmentation',
          key: 'segmentation',
          icon: 'fa fa-bars',
          children: [
            {
              title: 'Segmentation',
              key: 'segmentationGeneral',
              // icon: 'fa fa-sitemap',
              url: '/administration/segmentation',
              adminOnly: true,
            },
            {
              title: 'GTM Segmentation',
              key: 'segmentationGTM',
              // icon: 'fa fa-tasks',
              url: '/administration/gtm-segmentation',
              adminOnly: true,
            },
            {
              title: 'EL360 Status',
              key: 'segmentationEssilor',
              // icon: 'fa fa-tasks',
              url: '/administration/essilor-cluster',
              adminOnly: true,
            },
          ],
          adminOnly: true,
        },
        {
          title: 'Internationalisation',
          key: 'internationalisation',
          icon: 'fa fa-globe-europe',
          children: [
            {
              title: 'Countries',
              key: 'countries',
              url: '/administration/countries',
              adminOnly: true,
            },
            {
              title: 'iPad translations',
              key: 'ipadTranslations',
              url: '/internationalisation/ipad-translations',
              adminOnly: true,
            },
            {
              title: 'Order confirmation',
              key: 'orderConfirmation',
              url: '/internationalisation/order-confirmation',
              adminOnly: true,
            },
          ],
          adminOnly: true,
        },
      ],
    },
  ]
}
