import gql from 'graphql-tag'

export const INQUIRY_QUERY = gql`
  query inquiryName($id: ID!) {
    inquiry(id: $id) {
      name
    }
  }
`

export const CUSTOMER_QUERY = gql`
  query customerName($id: ID!) {
    customer(id: $id) {
      name
    }
  }
`

export const PARTNER_PROGRAM_QUERY = gql`
  query partnerProgramName($id: ID!) {
    partnerProgram(id: $id) {
      name
    }
  }
`
