import { store as reduxStore } from '../redux/sagas'

export const isLoggedIn = () => {
  const { user } = reduxStore.getState()
  return user && user.email
}

export const getValueFromLocalStorage = key => JSON.parse(localStorage.getItem(key))
export const writeToLocalStorage = (key, item) => localStorage.setItem(key, JSON.stringify(item))

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const camelToSnakeCase = str =>
  str && str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const delay = (time, v) => new Promise(resolve => setTimeout(resolve.bind(null, v), time))

export const DASHBOARD_ACTIVITY_FILTER_KEY = 'activityFilters'
export const DASHBOARD_CAMPAIGNS_FILTER_KEY = 'campaignStatisticFilters'
export const DASHBOARD_SEGMENTATION_FILTER_KEY = 'segmentationStatisticFilters'

export const REPORTS_ASSETS_COLUMN_KEY = 'assetsColumns'
export const REPORTS_ASSETS_FILTER_KEY = 'assetsFilter'

export const REPORTS_SALES_REPS_COLUMN_KEY = 'salesRepsColumns'
export const REPORTS_SALES_REPS_FILTER_KEY = 'salesRepsFilter'
export const REPORTS_SALES_REPS_AREA_MANAGER_KEY = 'salesRepsAreaManagerFilter'

export const REPORTS_CUSTOMERS_COLUMN_KEY = 'customersColumns'
export const REPORTS_CUSTOMERS_FILTER_KEY = 'customersFilter'

export const ASSETS_FILTER_KEY = 'assetManagementFilter'
export const ORDERS_FILTER_KEY = 'orderManagementFilter'
export const CUSTOMERS_FILTER_KEY = 'customerManagementFilter'
export const USERS_FILTER_KEY = 'userManagementFilter'
