import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Button } from 'antd'

const FilterMenuDropdown = ({
  data,
  filter,
  onClick,
  fieldName,
  isBoolean,
  customField,
  disabled,
  maxHeight,
  ...props
}) => {
  const [selected, setSelected] = useState()
  const [name, setName] = useState()
  const [items, setItems] = useState([])

  useEffect(() => {
    if (data) {
      const [collectionName] = Object.keys(data)
      setItems(data[collectionName].collection)
      setName(isBoolean ? collectionName : `${collectionName.slice(0, -1)}Id`)
    }
  }, [data, isBoolean])

  useEffect(() => {
    if (filter?.[name] && data && name) {
      setSelected(filter[name])
    }
  }, [filter, name, data])

  useEffect(() => {
    if (!filter || !Object.keys(filter).includes(name)) {
      setSelected()
    }
  }, [filter, name])

  const renderMenuItem = item => (
    <Menu.Item
      key={item.id}
      onClick={() => {
        setSelected(item.id)
        onClick(f => ({ ...f, [name]: isBoolean ? item.value : parseInt(item.id) }))
      }}
    >
      {item.name || item.title || item[customField]}
    </Menu.Item>
  )

  const renderMenu = () => (
    <Menu style={{ maxHeight, overflow: 'auto' }}>{items?.map(item => renderMenuItem(item))}</Menu>
  )

  return (
    <Dropdown
      {...props}
      style={{ cursor: 'pointer' }}
      overlay={renderMenu()}
      trigger={['click']}
      disabled={disabled}
    >
      <Button>
        {selected && items.find(item => item.id === selected)
          ? items.find(item => item.id === selected).name ||
            items.find(item => item.id === selected).title ||
            items.find(item => item.id === selected)[customField]
          : fieldName}{' '}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

FilterMenuDropdown.propTypes = {
  data: PropTypes.any.isRequired,
  filter: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  isBoolean: PropTypes.bool,
  customField: PropTypes.string,
  disabled: PropTypes.bool,
  maxHeight: PropTypes.any,
}

FilterMenuDropdown.defaultProps = {
  fieldName: 'select...',
  isBoolean: false,
  customField: undefined,
  disabled: false,
  maxHeight: '18rem',
}

export default FilterMenuDropdown
