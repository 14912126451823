import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import i18n from '@zauberware/react-i18n/web'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import loadLocale, { locales } from './utils/locales'

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings: { locale } }) => {
  useEffect(() => {
    i18n.changeLanguage(locales[locale.substr(0, 2)].name)
  }, [locale])
  const localeObject = loadLocale(locale)
  moment.locale(localeObject.moment)
  i18n.changeLanguage(locales[locale.substr(0, 2)].name)
  return <ConfigProvider locale={localeObject.antd}>{children}</ConfigProvider>
}

export default connect(mapStateToProps)(Localization)

Localization.propTypes = {
  children: PropTypes.any.isRequired,
  settings: PropTypes.any.isRequired,
}
