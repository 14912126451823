import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'

const StyledTag = ({ value, tags }) => {
  if (!tags) {
    return <Tag className="ant-form-text">{value}</Tag>
  }
  let searchId = 0
  if (typeof value === 'boolean') {
    if (value === true) {
      searchId = 1
    }
  } else {
    searchId = value
  }
  if (Array.isArray(tags)) {
    const returnValues = tags.find(tag => tag.id === Number(searchId))
    return <Tag color={returnValues.color}>{returnValues.label}</Tag>
  }
  return <Tag className="ant-form-text">{tags}</Tag>
}

StyledTag.propTypes = {
  value: PropTypes.any,
  tags: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf({
      id: PropTypes.number.isRequired,
      color: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ]),
}
StyledTag.defaultProps = {
  value: '',
  tags: '',
}
export default StyledTag
