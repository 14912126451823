import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  logo,
  isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => (
  <Layout>
    <Layout.Content>
      <div
        className={classNames(`${style.container}`, {
          cui__layout__squaredBorders: isSquaredBorders,
          cui__layout__cardsShadow: isCardShadow,
          cui__layout__borderless: isBorderless,
          [style.white]: authPagesColor === 'white',
        })}
      >
        <div className={style.containerInner}>{children}</div>
        <div className={`mt-auto pb-5 pt-5 ${style.containerFooter}`}>
          <div className="text-center">
            Copyright © 2020 Luxottica |{' '}
            <a
              href="https://www.luxottica.com/en/legal-disclaimer"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </a>
          </div>
        </div>
      </div>
    </Layout.Content>
  </Layout>
)

export default withRouter(connect(mapStateToProps)(AuthLayout))
