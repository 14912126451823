import React from 'react'

const ExampleContent = (
  <div className="d-flex flex-column">
    <div># Heading</div>
    <div>## Sub-heading</div>
    <div>### Another deeper heading</div>

    <div>Text attributes _italic_, *italic*, __bold__, **bold**.</div>

    <div>Bullet list:</div>
    <div>- apples</div>
    <div>- oranges</div>
    <div>- pears</div>

    <div>Numbered list:</div>
    <div>1. apples</div>
    <div>2. oranges</div>
    <div>3. pears</div>
  </div>
)
export default ExampleContent
