import React from 'react'
import PropTypes from 'prop-types'
import { DownOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Button } from 'antd'

const LanguageSwitch = ({ lang, setLang, keyset }) => {
  const renderMenuItem = item => (
    <Menu.Item
      key={item.id}
      onClick={() => {
        setLang({ ...item })
      }}
    >
      {item.id}
    </Menu.Item>
  )

  const renderMenu = () => <Menu>{keyset.map(item => renderMenuItem(item))}</Menu>
  return (
    <Dropdown
      className="mb-2"
      style={{ cursor: 'pointer' }}
      overlay={renderMenu()}
      trigger={['click']}
    >
      <Button>
        {(lang && keyset.find(item => item.id === lang?.id)?.id) || 'default'}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}

LanguageSwitch.propTypes = {
  lang: PropTypes.object.isRequired,
  setLang: PropTypes.func.isRequired,
  keyset: PropTypes.array.isRequired,
}

export default LanguageSwitch
