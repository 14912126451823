export default [
  { user: 'Christoph', tweets: 521, retweets: 428, favorites: 173 },
  { user: 'Christoph', tweets: 383, retweets: 542, favorites: 578 },
  { user: 'Christoph', tweets: 486, retweets: 345, favorites: 380 },
  { user: 'Christoph', tweets: 847, retweets: 354, favorites: 494 },
  { user: 'Christoph', tweets: 107, retweets: 665, favorites: 137 },
  { user: 'Christoph', tweets: 965, retweets: 412, favorites: 539 },
  { user: 'Christoph', tweets: 99, retweets: 867, favorites: 130 },
  { user: 'Christoph', tweets: 580, retweets: 366, favorites: 13 },
  { user: 'Christoph', tweets: 794, retweets: 802, favorites: 359 },
]
