import styled, { css } from 'styled-components'

const Flex = styled.div(
  props => css`
    display: flex;
    ${props.center &&
    css`
      align-items: center;
      justify-content: center;
    `}
    ${props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
  ${props.textCenter &&
    css`
      text-align: center;
    `}
  ${props.row &&
    css`
      flex-direction: row;
    `}
  ${props.column &&
    css`
      flex-direction: column;
    `}
  ${props.maxWidth &&
    css`
      max-width: ${typeof props.maxWidth === 'number' ? `${props.maxWidth}px` : props.maxWidth};
    `}
  ${props.width &&
    css`
      width: ${typeof props.width === 'number' ? `${props.width}px` : props.width};
    `}
  ${props.height &&
    css`
      height: ${props.height};
    `}
  ${props.spaceBetween &&
    css`
      justify-content: space-between;
    `}
  ${props.flexEnd &&
    css`
      justify-content: flex-end;
    `}
  ${props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `}
  ${props.absolute &&
    css`
      position: absolute;
    `}
  ${props.pointer &&
    css`
      cursor: pointer;
    `}
  ${props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}
  ${props.backgroundColor &&
    css`
      background: ${props.backgroundColor};
    `}
  ${props.background &&
    css`
      background: ${props.background};
    `}
  ${props.flexWrap &&
    css`
      flex-wrap: wrap;
    `}
  `
)

export default Flex
