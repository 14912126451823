import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from 'antd'
import { client } from '../../../services/graphql'

const CustomSelect = ({
  label,
  query,
  queryVariables,
  id,
  dropdownTitleName,
  dropdownMode,
  lazyLoad,
  data,
}) => {
  const [dropdownElements, setDropdownElements] = useState(null)
  const queryCallback = ({ data: res }) => {
    if (!dropdownElements) {
      const keys = Object.keys(res)
      const { collection } = res?.[keys?.[0]]
      setDropdownElements(collection)
    }
  }

  const queryFetch = () => {
    if (query) {
      client.query({ query, variables: queryVariables }).then(res => queryCallback(res, id))
    }
  }

  if (query && !lazyLoad) {
    queryFetch()
  }

  const arrayName = id.replace('Id', '')
  const array = data[arrayName]
  const { Option } = Select
  const elements = dropdownElements || array
  return (
    <Form.Item key={id} name={id} label={label}>
      <Select onFocus={queryFetch} onSearch={queryFetch} mode={dropdownMode}>
        {Array.isArray(elements) &&
          elements?.map(item => (
            <Option key={item.id} value={item.id}>
              {item[dropdownTitleName] || item.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
  )
}
CustomSelect.propTypes = {
  dropdownTitleName: PropTypes.string,
  dropdownMode: PropTypes.string,
  label: PropTypes.string,
  lazyLoad: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]).isRequired,
  query: PropTypes.object.isRequired,
  queryVariables: PropTypes.object,
  data: PropTypes.object.isRequired,
}
CustomSelect.defaultProps = {
  dropdownTitleName: null,
  dropdownMode: undefined,
  queryVariables: undefined,
  label: '',
  lazyLoad: false,
}

export default CustomSelect
