import React from 'react'
import PropTypes from 'prop-types'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import Flex from '../../atoms/Flex'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const CenteredSpinner = ({ width, height }) => (
  <Flex width={width} height={height} center>
    <Spin indicator={antIcon} />
  </Flex>
)
CenteredSpinner.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
CenteredSpinner.defaultProps = {
  width: null,
  height: null,
}

export default CenteredSpinner
