import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Tag, Tooltip } from 'antd'

const TagList = ({ elements, maxItems, tooltip, tooltipStyle }) => {
  const { t } = useTranslation('common')
  const [clicked, setClicked] = useState(false)
  const content = (max = elements.length) => (
    <div style={{ whiteSpace: 'break-spaces' }}>
      {elements?.slice(0, max)?.map(bl => (
        <Tag className="mb-1" key={bl.id}>
          {bl.name}
        </Tag>
      ))}
    </div>
  )
  return (
    <div>
      {clicked ? content() : content(maxItems)}
      {content().props.children.length - 5 > 0 ? (
        <>
          {tooltip ? (
            <Tooltip
              title={content()}
              trigger={['click']}
              getPopupContainer={() => document.body}
              overlayStyle={tooltipStyle}
            >
              <Tag color="geekblue">
                {t('clickToSeeMore', {
                  items: content().props.children.length - 5,
                })}
              </Tag>
            </Tooltip>
          ) : (
            <Tag color="geekblue" onClick={() => setClicked(!clicked)}>
              {clicked
                ? t('less')
                : t('clickToSeeMore', {
                    items: content().props.children.length - 5,
                  })}
            </Tag>
          )}
        </>
      ) : null}
    </div>
  )
}
export default TagList

TagList.propTypes = {
  elements: PropTypes.any.isRequired,
  maxItems: PropTypes.number,
  tooltip: PropTypes.bool,
  tooltipStyle: PropTypes.object,
}
TagList.defaultProps = {
  maxItems: undefined,
  tooltip: false,
  tooltipStyle: { maxWidth: 300 },
}
