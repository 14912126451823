import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

const List3 = ({ data, columns }) => {
  const renderListItem = item =>
    Object.keys(item)
      .filter(key => [...columns].includes(key))
      .map(key => (
        <li className={style.item}>
          <a href={item.href} className={style.itemLink}>
            <div className="mr-3">
              <div>{key}</div>
              <div className="text-muted">{item[key]}</div>
            </div>
            <div className={style.itemAction}>
              <span />
              <span />
            </div>
          </a>
        </li>
      ))
  return (
    <div>
      <ul className="list-unstyled">{data.map(item => renderListItem(item))}</ul>
    </div>
  )
}
List3.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
}
List3.defaultProps = {
  data: [
    {
      createdAt: '01.01.1999',
      updatedAt: '01.01.1999',
      name: 'Test123',
      slug: 'test-123',
    },
  ],
  columns: ['name', 'slug'],
}

export default List3
