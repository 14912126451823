import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Select, notification } from 'antd'

const { Option } = Select

const SearchDropdown = ({
  callback,
  disabled,
  placeholder,
  query,
  width,
  sortAttribute,
  value,
  onDataChange,
  parameters,
  customOptions,
}) => {
  const { t } = useTranslation('common')
  const [searchFilter, setSearchFilter] = useState('')
  const [searchOptions, setSearchOptions] = useState()
  const onDataLoadComplete = useCallback(
    data => {
      const keys = Object.keys(data)
      const { collection } = data[keys[0]]
      setSearchOptions(collection)
      onDataChange(collection)
    },
    [onDataChange]
  )
  const [fetchDataQuery] = useLazyQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      sortBy: {
        attribute: sortAttribute,
        direction: 'asc',
      },
      ...parameters,
      filter: { textSearch: searchFilter },
      limit: 20,
    },
    onCompleted: onDataLoadComplete,
    onError: () => notification.error({ message: t('error') }),
  })

  useEffect(() => {
    fetchDataQuery({
      onCompleted: onDataLoadComplete,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, fetchDataQuery])

  return (
    <Select
      disabled={disabled}
      showSearch
      showArrow
      style={{ display: 'block', width, minWidth: '120px', maxWidth: '220px' }}
      allowClear
      filterOption={false}
      placeholder={placeholder}
      onSearch={setSearchFilter}
      searchValue={searchFilter}
      onChange={callback}
      value={value}
      dropdownStyle={{
        minWidth: '200px',
      }}
    >
      {searchOptions &&
        searchOptions.map(b =>
          customOptions ? (
            customOptions(b)
          ) : (
            <Option id={b.id} value={b.id} key={b.id}>
              {b[sortAttribute]}
            </Option>
          )
        )}
    </Select>
  )
}
export default SearchDropdown

SearchDropdown.propTypes = {
  callback: PropTypes.func,
  customOptions: PropTypes.func,
  onDataChange: PropTypes.func,
  disabled: PropTypes.bool,
  sortAttribute: PropTypes.string,
  parameters: PropTypes.object,
  placeholder: PropTypes.string,
  query: PropTypes.object.isRequired,
  value: PropTypes.number,
  width: PropTypes.any,
}

SearchDropdown.defaultProps = {
  callback: () => {},
  customOptions: undefined,
  onDataChange: () => {},
  disabled: false,
  value: undefined,
  parameters: {},
  placeholder: undefined,
  width: 200,
  sortAttribute: 'name',
}
