import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import CacheBuster from 'react-cache-buster'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/client'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import i18n from '@zauberware/react-i18n/web'
import pck from '../package.json'
import Localization from './localization'
import { store, history } from './redux/sagas'
import Router from './routes/router'
import { client } from './services/graphql'
import * as serviceWorker from './serviceWorker'

i18n.init({
  lng: 'en',
  ns: ['common', 'errorMessages', 'validation', 'tableEssentials', 'order'],
  defaultNS: 'common',
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      traceFetch: true,
      traceXHR: true,
      tracePropagationTargets: [/zauberware.com/, /localhost/],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/zauberware.com/, /localhost/],
    }),
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
    new HttpClientIntegration(),
  ],
  release: pck.version,
  tracesSampleRate: 0.7,
  // This sets the sample rate to be 40%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.5,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  tracePropagationTargets: [/zauberware.com/, /localhost/],
})

render(
  <CacheBuster
    currentVersion={pck.version}
    isEnabled // If false, the library is disabled.
    isVerboseMode // If true, the library writes verbose logs to console.
    loadingComponent={null} // If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Localization>
          <Router history={history} />
        </Localization>
      </ApolloProvider>
    </Provider>
  </CacheBuster>,
  document.getElementById('root')
)

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
