import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Button, Drawer, notification } from 'antd'
import { UPDATE_IPAD_SETTINGS_MUTATION } from '../../../../../../services/graphql/mutations'
import { IPAD_SETTINGS_QUERY } from '../../../../../../services/graphql/queries'
import errorMessageHandler from '../../../../../atoms/ErrorMessageHandler'
import EditableFreeForm from '../../../../EditableFreeForm'

const mapStateToProps = ({ user }) => ({ isAdmin: user.superadmin })

const IPadSettings = ({ isAdmin }) => {
  const { t } = useTranslation('ipadSettings')

  const [drawerVisible, setDrawerVisible] = useState(false)
  const onClose = () => {
    setDrawerVisible(false)
  }

  const [ipadSettings, setIpadSettings] = useState(null)
  useQuery(IPAD_SETTINGS_QUERY, {
    onCompleted: data => {
      if (data) {
        setIpadSettings(data.ipadVersion)
      }
    },
  })
  const [updateIpadSettingsMutation] = useMutation(UPDATE_IPAD_SETTINGS_MUTATION, {
    onCompleted: data => {
      notification.success({
        message: t('success'),
      })
      if (data.data) {
        setIpadSettings(data.data.ipadSettings)
      }
    },
    onError: err => errorMessageHandler(err.message, t),
  })
  const elements = [
    {
      id: 'versionNumber',
      label: t('versionNumber'),
      type: isAdmin ? 'input' : 'text',
    },
    {
      id: 'downloadLink',
      label: t('downloadLink'),
      type: isAdmin ? 'input' : 'text',
    },
  ]
  return (
    <>
      <Button
        style={{ textAlign: 'left' }}
        type="link"
        block
        onClick={() => setDrawerVisible(true)}
      >
        {t('title')}
      </Button>
      <Drawer title={t('title')} width={720} visible={drawerVisible} onClose={onClose}>
        <EditableFreeForm
          elements={elements}
          data={ipadSettings}
          updateMutation={updateIpadSettingsMutation}
        />
      </Drawer>
    </>
  )
}
IPadSettings.propTypes = {
  isAdmin: PropTypes.bool,
}
IPadSettings.defaultProps = {
  isAdmin: false,
}

export default connect(mapStateToProps)(IPadSettings)
