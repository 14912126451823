import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '@zauberware/react-i18n/web'
import { DatePicker } from 'antd'
import moment from 'moment'

const FormDatePicker = ({ value, onChange, placeholder }) => {
  const [date, setDate] = useState(value && moment(value))
  const { t } = useTranslation('common')
  return (
    <DatePicker
      placeholder={placeholder || t('selectDate')}
      format="DD.MM.YYYY"
      defaultValue={date}
      onChange={d => {
        setDate(moment(d))
        onChange(moment(d).format('YYYY-MM-DD').toString())
      }}
    />
  )
}

FormDatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

FormDatePicker.defaultProps = {
  value: null,
  onChange: () => {},
  placeholder: null,
}

export default FormDatePicker
