import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { LIMIT } from '../../../../services/graphql/queries'

const FilterSortTable = ({
  bordered,
  columns,
  components,
  data,
  itemsPerPage,
  loading,
  onChange,
  rowClassName,
  totalCount,
  pagination,
  noPagination,
  expandable,
  ...restProps
}) => {
  const currentPagination = noPagination
    ? false
    : {
        position: 'bottomCenter',
        current: pagination?.current,
        total: totalCount,
        showLessItems: false,
        defaultPageSize: itemsPerPage,
      }
  return (
    <div>
      <div className="kit__utils__table">
        <Table
          {...restProps}
          components={components}
          bordered={bordered}
          rowClassName={rowClassName}
          loading={loading}
          columns={columns}
          dataSource={data}
          onChange={onChange}
          expandable={expandable}
          pagination={currentPagination}
        />
      </div>
    </div>
  )
}

FilterSortTable.propTypes = {
  bordered: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  components: PropTypes.object,
  expandable: PropTypes.object,
  data: PropTypes.array,
  itemsPerPage: PropTypes.number,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  rowClassName: PropTypes.func,
  totalCount: PropTypes.number,
  pagination: PropTypes.object,
  noPagination: PropTypes.bool,
}

FilterSortTable.defaultProps = {
  noPagination: false,
  bordered: false,
  components: undefined,
  expandable: undefined,
  data: null,
  itemsPerPage: LIMIT,
  loading: false,
  rowClassName: () => '',
  totalCount: 20,
  onChange: () => {},
  pagination: undefined,
}

export default FilterSortTable
