import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { EyeOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Input, Popover, Form } from 'antd'
import ExampleContent from './ExampleContent'

const MarkdownTextArea = ({ element, data }) => {
  const [markdown, setMarkdown] = useState()

  const updateMarkdown = val => {
    setMarkdown(val?.replaceAll('\n', '  \n'))
  }

  useEffect(() => {
    updateMarkdown(data[element.id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, data])

  const createPopover = (
    <>
      <Popover className="mx-2" content={ExampleContent}>
        <InfoCircleOutlined />
      </Popover>

      <Popover
        content={
          <div
            style={{
              minWidth: 300,
              minHeight: 200,
              maxWidth: 500,
              maxHeight: 500,
              overflow: 'scroll',
            }}
          >
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </div>
        }
      >
        <EyeOutlined />
      </Popover>
    </>
  )
  const getLabel = label => {
    if (typeof label === 'object') {
      return (
        <div>
          <div className="font-weight-bolder">{label.title}</div>
          <div className="d-flex align-items-center">
            <div className="font-weight-lighter">{label.subtitle}</div>
            {createPopover}
          </div>
        </div>
      )
    }
    return (
      <>
        <div className="font-weight-bolder">{label}</div>
        {createPopover}
      </>
    )
  }

  return (
    <Form.Item key={element.id} name={element.id} label={getLabel(element.label)}>
      <Input.TextArea
        placeholder={element.placeholder ? element.placeholder : element.label}
        onChange={e => updateMarkdown(e.target.value)}
      />
    </Form.Item>
  )
}
export default MarkdownTextArea

MarkdownTextArea.propTypes = {
  element: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
}
