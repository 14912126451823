import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { Layout, Spin } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import Menu from '../../components/organisms/Menu'
import TopBar from '../../components/organisms/TopBar'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const MainLayout = ({
  children,
  isAppMaxWidth,
  isBorderless,
  isCardShadow,
  isContentMaxWidth,
  isGrayBackground,
  isGrayTopbar,
  isSquaredBorders,
  isTopbarFixed,
}) => (
  <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
    <Layout
      className={classNames({
        cui__layout__contentMaxWidth: isContentMaxWidth,
        cui__layout__appMaxWidth: isAppMaxWidth,
        cui__layout__grayBackground: isGrayBackground,
        cui__layout__squaredBorders: isSquaredBorders,
        cui__layout__cardsShadow: isCardShadow,
        cui__layout__borderless: isBorderless,
      })}
    >
      <Menu />
      <Layout>
        <Layout.Header
          className={classNames('cui__layout__header', {
            cui__layout__fixedHeader: isTopbarFixed,
            cui__layout__headerGray: isGrayTopbar,
          })}
        >
          <TopBar />
        </Layout.Header>

        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className="cui__utils__content">{children}</div>
        </Layout.Content>
      </Layout>
    </Layout>
  </div>
)

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isContentMaxWidth: PropTypes.bool.isRequired,
  isAppMaxWidth: PropTypes.bool.isRequired,
  isGrayBackground: PropTypes.bool.isRequired,
  isSquaredBorders: PropTypes.bool.isRequired,
  isCardShadow: PropTypes.bool.isRequired,
  isBorderless: PropTypes.bool.isRequired,
}

export default withRouter(connect(mapStateToProps)(MainLayout))
