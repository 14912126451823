import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

const StyledMutationLink = ({ label, onClick, button }) => {
  if (!onClick) return null
  if (!label) return null
  return button ? (
    <Button
      style={{ width: 200 }}
      type="secondary"
      className="mr-3 mb-3"
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
    >
      {label}
    </Button>
  ) : (
    <button
      type="button"
      className="btn btn-link"
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
    >
      {label}
    </button>
  )
}
StyledMutationLink.propTypes = {
  label: PropTypes.any.isRequired,
  button: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
StyledMutationLink.defaultProps = {
  button: false,
}
export default StyledMutationLink
