import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation ($email: String!, $password: String!, $rememberMe: Boolean!) {
    signIn(email: $email, password: $password, rememberMe: $rememberMe) {
      errors {
        details
      }
      success
      user {
        createdAt
        email
        firstName
        id
        isAreaManager
        superadmin
      }
    }
  }
`

export const REGISTER_MUTATION = gql`
  mutation ($email: String!, $password: String!, $passwordConfirmation: String!) {
    signUp(email: $email, password: $password, passwordConfirmation: $passwordConfirmation) {
      errors {
        details
      }
      success
      user {
        createdAt
        email
        firstName
        id
      }
    }
  }
`

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email) {
      success
      errors {
        details
      }
      valid
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ($resetPasswordToken: String!, $password: String!, $passwordConfirmation: String!) {
    resetPassword(
      resetPasswordToken: $resetPasswordToken
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      success
      errors {
        details
      }
    }
  }
`

export const VALIDATE_TOKEN_MUTATION = gql`
  mutation {
    validateToken {
      errors {
        details
      }
      success
      valid
      user {
        id
        name
        createdAt
        email
        firstName
        lastName
        isAreaManager
        superadmin
      }
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation ($id: ID!, $attributes: UserInput!) {
    updateUser(id: $id, attributes: $attributes) {
      id
      email
      firstName
      lastName
      archivedAt
      phone
      mobile
      fieldManager
      brandManager
      translator
      isAreaManager
      superadmin
      areaManager {
        id
        name
      }
      managedUsers {
        id
        name
      }
      repId
      rtb
      wantsOrderConfirmations
      brandLines {
        id
        name
      }
    }
  }
`

export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation ($id: ID!, $attributes: UserInput!) {
    updateUser(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_USER_ROLE_MUTATION = gql`
  mutation ($id: ID!, $role: String!) {
    updateUserRole(id: $id, role: $role)
  }
`
// delete user mutation
export const DELETE_USER_MUTATION = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id)
  }
`

export const UPDATE_ASSET_CONTENT_MUTATION = gql`
  mutation ($id: ID!, $attributes: AssetContentInput!) {
    updateAssetContent(id: $id, attributes: $attributes) {
      id
      productCode
      brand {
        id
      }
      campaign {
        id
      }
      title
      titleDe
      titleIt
      titleFr
      titleEn
      price
      sizes
      accessibleUntil
      shippingFrom
      glassesModel
      mandatoryInformation
      mandatoryInformationDe
      mandatoryInformationEn
      mandatoryInformationFr
      mandatoryInformationIt
      material
      materialDe
      materialEn
      materialFr
      materialIt
      description
      descriptionDe
      descriptionIt
      descriptionFr
      descriptionEn
      needsMandatoryOrderInfo
      sendOrderInfoToLogisticCenter
      excludeFromOrderConfirmation
      stock
    }
  }
`

export const CREATE_ASSET_MUTATION = gql`
  mutation ($attributes: AssetInput!) {
    createAsset(attributes: $attributes) {
      id
      amount
      title
      onHold
      repDistributionEnd
      shippingDateToLogisticCenter
      logisticCenterId
      logisticCenter {
        name
      }
      brandLine {
        id
        name
      }
    }
  }
`

export const UPDATE_ASSET_MUTATION = gql`
  mutation ($id: ID!, $attributes: AssetInput!) {
    updateAsset(id: $id, attributes: $attributes) {
      id
      amount
      logisticCenterId
      onHold
      repDistributionEnd
      logisticCenter {
        name
      }
    }
  }
`

export const UPDATE_LOGISTIC_CENTER_MUTATION = gql`
  mutation ($id: ID!, $attributes: LogisticCenterInput!) {
    updateLogisticCenter(id: $id, attributes: $attributes) {
      id
      name
      brandLines {
        id
      }
      createdAt
      updatedAt
      deliveryTime
      email
      email2
      email3
      email4
      email5
      emailInfo
      onHold
    }
  }
`

// CK - TODO: add campaign mutation
export const UPDATE_CAMPAIGN_MUTATION = gql`
  mutation ($id: ID!, $attributes: AssetContentInput!) {
    updateAssetContent(id: $id, attributes: $attributes) {
      id
      productCode
      brand {
        name
      }
      title
      titleDe
      titleIt
      titleFr
      titleEn
      price
      sizes
      accessibleUntil
      shippingFrom
      glassesModel
      material
      materialDe
      materialEn
      description
      descriptionDe
      descriptionIt
      descriptionFr
      descriptionEn
      needsMandatoryOrderInfo
      sendOrderInfoToLogisticCenter
      excludeFromOrderConfirmation
    }
  }
`

export const UPDATE_IPAD_SETTINGS_MUTATION = gql`
  mutation ($id: ID!, $attributes: IpadVersionInput!) {
    updateIpadVersion(id: $id, attributes: $attributes) {
      id
      versionNumber
      downloadLink
    }
  }
`

export const CREATE_IPAD_TRANSLATION = gql`
  mutation ($attributes: IpadTranslationInput!) {
    createIpadTranslation(attributes: $attributes) {
      id
      key
      languageKey
      translation
    }
  }
`

export const UPDATE_IPAD_TRANSLATION = gql`
  mutation ($id: ID!, $attributes: IpadTranslationInput!) {
    updateIpadTranslation(id: $id, attributes: $attributes) {
      id
      key
      languageKey
      translation
    }
  }
`

export const MERGE_CUSTOMER_MUTATION = gql`
  mutation ($customerIds: [Int!]!, $attributes: CustomerMultipleInput!) {
    updateMultipleCustomers(customerIds: $customerIds, attributes: $attributes)
  }
`

export const DELETE_CUSTOMER_GROUP_MUTATION = gql`
  mutation ($id: ID!) {
    deleteCustomerGroup(id: $id)
  }
`

export const CREATE_CUSTOMER_GROUP_MUTATION = gql`
  mutation ($attributes: CustomerGroupInput!) {
    createCustomerGroup(attributes: $attributes) {
      id
      name
      position
    }
  }
`

export const UPDATE_CUSTOMER_GROUP_MUTATION = gql`
  mutation ($id: ID!, $attributes: CustomerGroupInput!) {
    updateCustomerGroup(id: $id, attributes: $attributes) {
      id
      name
      position
    }
  }
`

export const DELETE_CUSTOMER_ESSILOR_GROUP_MUTATION = gql`
  mutation ($id: ID!) {
    deleteCustomerEssilorGroup(id: $id)
  }
`

export const CREATE_CUSTOMER_ESSILOR_GROUP_MUTATION = gql`
  mutation ($attributes: CustomerEssilorGroupInput!) {
    createCustomerEssilorGroup(attributes: $attributes) {
      id
      name
      position
    }
  }
`

export const UPDATE_CUSTOMER_ESSILOR_GROUP_MUTATION = gql`
  mutation ($id: ID!, $attributes: CustomerEssilorGroupInput!) {
    updateCustomerEssilorGroup(id: $id, attributes: $attributes) {
      id
      name
      position
    }
  }
`

export const DELETE_CUSTOMER_GTM_GROUP_MUTATION = gql`
  mutation ($id: ID!) {
    deleteCustomerGtmGroup(id: $id)
  }
`

export const CREATE_CUSTOMER_GTM_GROUP_MUTATION = gql`
  mutation ($attributes: CustomerGtmGroupInput!) {
    createCustomerGtmGroup(attributes: $attributes) {
      id
      name
      position
    }
  }
`

export const UPDATE_CUSTOMER_GTM_GROUP_MUTATION = gql`
  mutation ($id: ID!, $attributes: CustomerGtmGroupInput!) {
    updateCustomerGtmGroup(id: $id, attributes: $attributes) {
      id
      name
      position
    }
  }
`

export const DELETE_BRAND_LINE_MUTATION = gql`
  mutation ($id: ID!) {
    deleteBrandLine(id: $id)
  }
`

export const CREATE_BRAND_LINE_MUTATION = gql`
  mutation ($attributes: BrandLineInput!) {
    createBrandLine(attributes: $attributes) {
      id
      name
    }
  }
`

export const UPDATE_BRAND_LINE_MUTATION = gql`
  mutation ($id: ID!, $attributes: BrandLineInput!) {
    updateBrandLine(id: $id, attributes: $attributes) {
      id
      name
      brand {
        id
        name
      }
      isArchived
      assetManagement
      hasPartnerPrograms
      countryId
      languages
    }
  }
`

// CK - TODO: add brand mutation
export const UPDATE_BRAND_MUTATION = gql`
  mutation ($id: ID!, $attributes: BrandInput!) {
    updateBrand(id: $id, attributes: $attributes) {
      id
      name
      primaryColor
    }
  }
`

export const UPDATE_ORDER_MUTATION = gql`
  mutation ($id: ID!, $attributes: OrderInput!) {
    updateBrand(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const CREATE_COUNTRY_MUTATION = gql`
  mutation ($attributes: CountryInput!) {
    createCountry(attributes: $attributes) {
      id
      name
      imprint
      supportEmail
    }
  }
`

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation ($id: ID!, $attributes: CustomerInput!) {
    updateCustomer(id: $id, attributes: $attributes) {
      id
      countryId
      name
      customerNo
      essilorCustomerNo
      street
      postzip
      city
      phone
      mobile
      email
      noMassOrders
      contactPerson
      channelOfTrade
      lang
      duplicateCustomerId
      ignoreInDulicateList
      customerGroupIds
      customerGtmGroupIds
      customerEssilorGroupIds
      brandLineIds
    }
  }
`

export const CREATE_USER_CARES_CUSTOMER_MUTATION = gql`
  mutation createUserCaresCustomer($attributes: UserCaresCustomerInput!) {
    createUserCaresCustomer(attributes: $attributes) {
      id
      user {
        id
        name
      }
      brandLine {
        id
        name
      }
    }
  }
`

export const DELETE_USER_CARES_CUSTOMER_MUTATION = gql`
  mutation deleteUserCaresCustomer($id: ID!) {
    deleteUserCaresCustomer(id: $id)
  }
`

export const SEND_ORDER_CONFIRMATION_MUTATION = gql`
  mutation ($id: ID!) {
    sendOrderConfirmation(orderId: $id)
  }
`

export const SEND_REP_ORDER_CONFIRMATION_MUTATION = gql`
  mutation ($id: ID!) {
    sendRepOrderConfirmation(orderId: $id)
  }
`

export const UPDATE_COUNTRY_MUTATION = gql`
  mutation ($id: ID!, $attributes: CountryInput!) {
    updateCountry(id: $id, attributes: $attributes) {
      id
      name
      imprint
      supportEmail
    }
  }
`

export const ARCHIVE_CUSTOMER_MUTATION = gql`
  mutation ($id: ID!) {
    archiveCustomer(id: $id)
  }
`

export const DELETE_COUNTRY_MUTATION = gql`
  mutation ($id: ID!) {
    deleteCountry(id: $id)
  }
`

export const DELETE_ASSET_MUTATION = gql`
  mutation ($id: ID!) {
    deleteAsset(id: $id)
  }
`

export const DELETE_ASSET_CONTENT_MUTATION = gql`
  mutation ($id: ID!) {
    deleteAssetContent(id: $id)
  }
`
export const CREATE_CUSTOMER_MUTATION = gql`
  mutation ($attributes: CustomerInput!) {
    createCustomer(attributes: $attributes) {
      id
      name
    }
  }
`

export const CREATE_ORDERED_ITEM_MUTATION = gql`
  mutation ($attributes: OrderedItemInput!) {
    createOrderedItem(attributes: $attributes) {
      id

      alreadyShipped
      amount

      orderId
      assetId
      unscopedAsset {
        assetContent {
          id
          productCode
          title
        }
      }
      updatedAt
      orderMessage
    }
  }
`

export const DELETE_ORDER_MUTATION = gql`
  mutation ($id: ID!) {
    deleteOrderedItem(id: $id)
  }
`

export const BULK_DELETE_ORDERED_ITEMS_MUTATION = gql`
  mutation bulkDeleteOrderedItems($ids: [ID!]!) {
    bulkDeleteOrderedItems(ids: $ids)
  }
`

export const UPDATE_ORDERED_ITEM_AMOUNT_MUTATION = gql`
  mutation ($orderedItemId: ID!, $amount: UpdateAmountEnum!) {
    updateAmount(orderedItemId: $orderedItemId, amount: $amount) {
      id
      amount
      asset {
        title
        amount
        isFcfs
      }
    }
  }
`

export const CREATE_ORDER_MUTATION = gql`
  mutation ($attributes: OrderInput!) {
    createOrder(attributes: $attributes) {
      id
    }
  }
`

export const CREATE_LOGISTICCENTER_MUTATION = gql`
  mutation ($attributes: LogisticCenterInput!) {
    createLogisticCenter(attributes: $attributes) {
      id
    }
  }
`

export const DELETE_LOGISTICCENTER_MUTATION = gql`
  mutation ($id: ID!) {
    deleteLogisticCenter(id: $id)
  }
`

export const UPLOAD_FILE_MUTATION = gql`
  mutation (
    $filename: String!
    $attribute: String!
    $resourceId: Int
    $resourceClass: String!
    $parentId: Int
    $parentClass: String
    $isParentResource: Boolean
  ) {
    uploadFile(
      filename: $filename
      attribute: $attribute
      resourceId: $resourceId
      resourceClass: $resourceClass
      parentId: $parentId
      parentClass: $parentClass
      isParentResource: $isParentResource
    ) {
      id
    }
  }
`

export const DELETE_FILE_MUTATION = gql`
  mutation ($resourceId: Int!, $resourceClass: String!, $attribute: String) {
    deleteFile(attribute: $attribute, resourceId: $resourceId, resourceClass: $resourceClass)
  }
`

export const CREATE_USER_MUTATION = gql`
  mutation ($attributes: UserInput!) {
    createUser(attributes: $attributes) {
      id
    }
  }
`
export const UPDATE_ASSET_CONTENT_AVAILABILITIES_MUTATION = gql`
  mutation ($id: ID!, $attributes: AvailabilityInput!) {
    updateAvailability(id: $id, attributes: $attributes) {
      id
      allowed
    }
  }
`

export const CREATE_ASSET_USER_RATIONING_MUTATION = gql`
  mutation ($attributes: RationingInput!) {
    createRationing(attributes: $attributes) {
      id
      amount
      distributed
      distributedThisYear
      user {
        id
      }
    }
  }
`
export const UPDATE_ASSET_USER_RATIONING_MUTATION = gql`
  mutation ($id: ID!, $attributes: RationingInput!) {
    updateRationing(id: $id, attributes: $attributes) {
      id
      amount
      user {
        id
      }
    }
  }
`

export const DELETE_ASSET_USER_RATIONING_MUTATION = gql`
  mutation ($id: ID!) {
    deleteRationing(id: $id)
  }
`

export const CREATE_ASSET_CONTENT_MUTATION = gql`
  mutation ($attributes: AssetContentInput!) {
    createAssetContent(attributes: $attributes) {
      id
      title
    }
  }
`

export const DUPLICATE_ASSET_CONTENT_MUTATION = gql`
  mutation ($id: ID!) {
    duplicateAssetContent(id: $id) {
      id
    }
  }
`

export const CREATE_PARTNER_PROGRAM_MUTATION = gql`
  mutation ($attributes: PartnerProgramInput!) {
    createPartnerProgram(attributes: $attributes) {
      id
      name
    }
  }
`

export const DELETE_PARTNER_PROGRAM_MUTATION = gql`
  mutation ($id: ID!) {
    deletePartnerProgram(id: $id)
  }
`
export const UPDATE_PARTNER_PROGRAM_MUTATION = gql`
  mutation ($id: ID!, $attributes: PartnerProgramInput!) {
    updatePartnerProgram(id: $id, attributes: $attributes) {
      id
      name
      nameDe
      nameEn
      nameFr
      nameIt
      description
      descriptionDe
      descriptionEn
      descriptionFr
      descriptionIt
      subscribeEmailSubject
      subscribeEmailSubjectDe
      subscribeEmailSubjectEn
      subscribeEmailSubjectFr
      subscribeEmailSubjectIt
      subscribeEmailContent
      subscribeEmailContentDe
      subscribeEmailContentEn
      subscribeEmailContentFr
      subscribeEmailContentIt
      unsubscribeEmailSubject
      unsubscribeEmailSubjectDe
      unsubscribeEmailSubjectEn
      unsubscribeEmailSubjectFr
      unsubscribeEmailSubjectIt
      unsubscribeEmailContent
      unsubscribeEmailContentDe
      unsubscribeEmailContentEn
      unsubscribeEmailContentFr
      unsubscribeEmailContentIt
      isSubscriptionEmailActive
      isUnsubscriptionEmailActive
      notifyAdminsForChanges
      startDate
      endDate
      activeCustomersCount
      inactiveCustomersCount
      isScheduledExportActive
      emailsForExport
      interval
      day
      wday
      emailsForNotifications
      inquiries {
        id
        name
        nameDe
        nameEn
        nameFr
        nameIt
        subscribeEmailContent
        subscribeEmailContentDe
        subscribeEmailContentEn
        subscribeEmailContentFr
        subscribeEmailContentIt
        subscribeEmailSubject
        subscribeEmailSubjectDe
        subscribeEmailSubjectEn
        subscribeEmailSubjectFr
        subscribeEmailSubjectIt
        startDate
        endDate
        isInitial
        isSubscriptionEmailActive
        subscribeEmailContent
        active
      }
      brandLines {
        id
        name
      }
      isArchived
    }
  }
`
export const PARTNER_PROGRAM_TESTMAIL_MUTATION = gql`
  mutation ($id: ID!) {
    partnerProgramTriggerEmail(id: $id)
  }
`

export const TOGGLE_CUSTOMER_TO_PARTNER_PROGRAM_CONNECTIONS_MUTATION = gql`
  mutation ($id: ID!) {
    toggleCustomerToPartnerProgramActive(id: $id) {
      id
      isActive
    }
  }
`
export const DELETE_CUSTOMER_TO_PARTNER_PROGRAM_CONNECTIONS_MUTATION = gql`
  mutation ($id: ID!) {
    deleteCustomerToPartnerProgramConnection(id: $id)
  }
`

export const CREATE_INQUIRY_MUTATION = gql`
  mutation ($attributes: InquiryInput!) {
    createInquiry(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_INQUIRY_MUTATION = gql`
  mutation ($id: ID!, $attributes: InquiryInput!) {
    updateInquiry(id: $id, attributes: $attributes) {
      name
      participants {
        id
        customerNo
        name
        street
        postzip
        city
        contactPerson
      }
      questions {
        id
      }
      isSubscriptionEmailActive
      subscribeEmailSubject
      subscribeEmailSubjectDe
      subscribeEmailSubjectEn
      subscribeEmailSubjectFr
      subscribeEmailSubjectIt
      subscribeEmailContent
      subscribeEmailContentDe
      subscribeEmailContentEn
      subscribeEmailContentFr
      subscribeEmailContentIt
    }
  }
`

export const DELETE_INQUIRY_MUTATION = gql`
  mutation ($id: ID!) {
    deleteInquiry(id: $id)
  }
`

export const CREATE_QUESTION_MUTATION = gql`
  mutation ($attributes: QuestionInput!) {
    createQuestion(attributes: $attributes) {
      id
      type
      questionText
      questionTextDe
      questionTextEn
      questionTextFr
      questionTextIt
      answerOptions {
        id
        answerText
        answerTextDe
        answerTextEn
        answerTextFr
        answerTextIt
      }
    }
  }
`

export const UPDATE_QUESTION_MUTATION = gql`
  mutation ($id: ID!, $attributes: QuestionInput!) {
    updateQuestion(id: $id, attributes: $attributes) {
      id
      questionText
      questionTextDe
      questionTextEn
      questionTextFr
      questionTextIt
      answerOptions {
        id
        answerText
        answerTextDe
        answerTextEn
        answerTextFr
        answerTextIt
      }
    }
  }
`

export const DELETE_QUESTION_MUTATION = gql`
  mutation ($id: ID!) {
    deleteQuestion(id: $id)
  }
`

export const CREATE_ANSWER_MUTATION = gql`
  mutation ($attributes: AnswerOptionInput!) {
    createAnswerOption(attributes: $attributes) {
      id
      answerText
      answerTextDe
      answerTextEn
      answerTextFr
      answerTextIt
    }
  }
`

export const UPDATE_ANSWER_MUTATION = gql`
  mutation ($id: ID!, $attributes: AnswerOptionInput!) {
    updateAnswerOption(id: $id, attributes: $attributes) {
      id
      answerText
      answerTextDe
      answerTextEn
      answerTextFr
      answerTextIt
    }
  }
`

export const DELETE_ANSWER_MUTATION = gql`
  mutation ($id: ID!) {
    deleteAnswerOption(id: $id)
  }
`

export const TOGGLE_ALREADY_SHIPPED_MUTATION = gql`
  mutation ($id: ID!) {
    toggleAlreadyShipped(orderedItemId: $id) {
      id
      alreadyShipped
    }
  }
`

export const UPDATE_QUESTION_ANSWER_MUTATION = gql`
  mutation updateQuestionAnswer($id: ID!, $attributes: QuestionAnswerInput!) {
    updateQuestionAnswer(id: $id, attributes: $attributes) {
      id
      questionId
      answerText
      userId
      userName
      answerOptionIds
      customerId
      answerToBinaryQuestion
      answerDate
      answerNumber
    }
  }
`
