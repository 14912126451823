import i18n from '@zauberware/react-i18n/web'

const errorMessages = [
  {
    name: 'Password is too short (minimum is 8 characters)',
    value: i18n.t('errorMessages:passwordToShort'),
  },
  { name: 'Email has already been taken', value: i18n.t('errorMessages:emailAlreadyTaken') },
  {
    name: "Password confirmation doesn't match Password",
    value: i18n.t('errorMessages:passwordMatchErr'),
  },
  { name: "Name can't be blank", value: i18n.t('errorMessages:nameNotBlank') },
  { name: "Email can't be blank", value: i18n.t('errorMessages:emailNotBlank') },
  { name: 'Email is invalid', value: i18n.t('errorMessages:emailInvalid') },
  { name: "Password can't be blank", value: i18n.t('errorMessages:passwordNotBlank') },
  {
    name: 'Product code has already been taken',
    value: i18n.t('errorMessages:productCodeAlreadyTaken'),
  },
  { name: "Product code can't be blank", value: i18n.t('errorMessages:productCodeNotBlank') },
  { name: "Title can't be blank", value: i18n.t('errorMessages:productTitleNotBlank') },
  { name: "Version number can't be blank", value: i18n.t('errorMessages:versionNumberNotBlank') },
  { name: "Download link can't be blank", value: i18n.t('errorMessages:downloadLinkNotBlank') },
]

export default errorMessages
