import { all } from 'redux-saga/effects'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { logger } from 'redux-logger'

import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import reducers from './reducers'

// middlewared
export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger)
}

export const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares)),
  window.__REDUX_DEVTOOLs_EXTENSION__ && window.__REDUX_DEVTOOLs_EXTENSION__()
)

function* rootSaga() {
  yield all([user(), menu(), settings()])
}
sagaMiddleware.run(rootSaga)
