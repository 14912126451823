import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SyncOutlined } from '@ant-design/icons'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Button, Drawer, Select, Space, Tag } from 'antd'
import moment from 'moment'
import {
  BACKGROUND_JOBS_QUERY,
  DROPDOWN_USERS_QUERY,
} from '../../../../../../services/graphql/queries'
import { useTableFilterAndSort } from '../../../../../../utils/hooks'
import Flex from '../../../../../atoms/Flex'
import FilterSortTable from '../../../../../molecules/Tables/FilterSorter'

const { Option } = Select

const mapStateToProps = ({ user }) => ({
  userId: user.id,
  isAreaManager: user.isAreaManager,
})

const Tasks = ({ userId, isAreaManager }) => {
  const { t } = useTranslation('backgroundTasks')
  const [tasks, setTasks] = useState(null)
  const [fetchBackgroundJobs] = useLazyQuery(BACKGROUND_JOBS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data && data.backgroundTasks.collection) {
        let { collection } = { ...data.backgroundTasks }
        collection = collection.map(u => {
          const newU = { ...u }
          newU.createdAt = moment(u.createdAt).format('DD.MM.YYYY - hh:mm')
          return newU
        })
        setTasks(collection)
        setTotalCount(data.backgroundTasks.metadata.totalCount)
      }
      setLoading(false)
    },
    onError: () => setLoading(false),
  })
  const {
    loading,
    setLoading,
    handleReset,
    onChange,
    totalCount,
    setTotalCount,
    sort,
    filter,
    setFilter,
  } = useTableFilterAndSort({ fetchQuery: fetchBackgroundJobs })
  useEffect(() => {
    setFilter(f => ({ ...f, userId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
  const [users, setUsers] = useState(null)
  useQuery(DROPDOWN_USERS_QUERY, {
    variables: {
      limit: 500,
      sortBy: { direction: 'asc', attribute: 'lastName' },
    },
    onCompleted: d => {
      if (d && d.users && d.users.collection) setUsers(d.users.collection)
    },
  })
  const handleUserChange = value => {
    setFilter({ ...filter, userId: value })
  }
  const [drawerVisible, setDrawerVisible] = useState(false)
  const onClose = () => {
    setDrawerVisible(false)
  }
  const columns = [
    {
      title: t('tableEssentials:id'),
      id: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('tableEssentials:name'),
      id: 'type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('tableEssentials:status'),
      id: 'status',
      dataIndex: 'status',
      key: 'status',
      render: status => <Tag color={status === 'success' ? 'green' : 'orange'}>{t(status)}</Tag>,
    },
    {
      title: t('brandLine'),
      id: 'brandLine',
      dataIndex: 'brandLine',
      key: 'brandLineId',
      render: brandLine => (brandLine ? brandLine.name : ''),
    },
    {
      title: t('user'),
      id: 'user',
      dataIndex: 'user',
      key: 'userId',
      render: user => (user ? user.name : ''),
    },
    {
      title: t('tableEssentials:createdAt'),
      id: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: t('document'),
      id: 'document',
      dataIndex: 'authenticatedDocumentUrl',
      key: 'authenticatedDocumentUrl',
      render: (url, { status }) => (
        <Button loading={status !== 'success'} type="primary" href={url}>
          {status !== 'success' ? t('processing') : t('tableEssentials:download')}
        </Button>
      ),
    },
  ]
  return (
    <>
      <Button
        style={{ textAlign: 'left' }}
        type="link"
        block
        onClick={() => setDrawerVisible(true)}
      >
        {t('title')}
      </Button>
      <Drawer title={t('title')} width={1080} visible={drawerVisible} onClose={onClose}>
        {!isAreaManager && (
          <Space style={{ marginBottom: 16 }}>
            <Select
              showSearch
              style={{ minWidth: 150 }}
              optionFilterProp="name"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder={t('selectUser')}
              onChange={handleUserChange}
              value={filter && filter.userId}
            >
              {users &&
                users.map(b => (
                  <Option value={b.id} key={b.id}>
                    {b.name}
                  </Option>
                ))}
            </Select>
            <Button onClick={() => handleReset()} size="medium">
              {t('resetFilters')}
            </Button>
          </Space>
        )}
        <Flex className="mb-2" flexEnd>
          <Button
            icon={<SyncOutlined />}
            onClick={() =>
              fetchBackgroundJobs({
                variables: {
                  filter,
                  sortBy: sort,
                },
              })
            }
          />
        </Flex>
        <FilterSortTable
          onChange={onChange}
          loading={loading}
          totalCount={totalCount}
          data={tasks}
          columns={columns}
        />
      </Drawer>
    </>
  )
}
Tasks.propTypes = {
  userId: PropTypes.number.isRequired,
  isAreaManager: PropTypes.bool,
}
Tasks.defaultProps = {
  isAreaManager: false,
}

export default connect(mapStateToProps)(Tasks)
