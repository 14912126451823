const env = process.env.REACT_APP_ENV

const appConfig = {
  apollo: {
    networkInterface: {
      development: 'http://localhost:3000/graphql',
      staging: 'https://luxottica-api-staging.zauberware.com/graphql',
      production: 'https://luxottica-api.zauberware.com/graphql',
    }[env],
    loginRoute: {
      development: 'http://localhost:3000/login',
      staging: 'https://luxottica-api-staging.zauberware.com/login',
      production: 'https://luxottica-api.zauberware.com/login',
    }[env],
    baseURL: {
      development: 'http://localhost:3000',
      staging: 'https://luxottica-api-staging.zauberware.com',
      production: 'https://luxottica-api.zauberware.com',
    }[env],
  },
}

export default appConfig
