import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { UserOutlined } from '@ant-design/icons'
import { Button, Menu, Dropdown, Avatar, Spin } from 'antd'
import IPadSettings from './components/IPadSettings'
import Tasks from './components/Tasks'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const AdminMenu = ({ dispatch, user }) => {
  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <Button style={{ textAlign: 'left' }} type="text" block>
          Hello, {user.name || 'Anonymous'}
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button style={{ textAlign: 'left' }} type="text" block>
          <strong>Email: </strong>
          {user.email || '—'}
        </Button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <IPadSettings />
      </Menu.Item>
      <Menu.Item>
        <Tasks />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Button style={{ textAlign: 'left' }} type="link" block onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  )
  return (
    <Suspense fallback={<Spin />}>
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </div>
      </Dropdown>
    </Suspense>
  )
}
AdminMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
}
AdminMenu.defaultProps = {
  user: null,
}

export default connect(mapStateToProps)(AdminMenu)
