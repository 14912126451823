import gql from 'graphql-tag'

export const LIMIT = 10

export const USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      name
      email
      createdAt
      updatedAt
      archivedAt
      archived
      name
      phone
      mobile
      fieldManager
      brandManager
      translator
      isAreaManager
      superadmin
      areaManager {
        id
        name
      }
      managedUsers {
        id
        name
      }
      repId
      rtb
      wantsOrderConfirmations
      brandLines {
        id
        name
      }
    }
  }
`

export const REPORTS_USER_TITLE_QUERY = gql`
  query reportsUserTitle($id: ID!) {
    user(id: $id) {
      id
      name
    }
  }
`

export const REPORTS_SALES_REPS_DETAIL_QUERY = gql`
  query reportsSalesRepsDetail($id: ID!) {
    user(id: $id) {
      id
      rationings {
        id
        asset {
          id
          title
          brandLine {
            id
            name
          }
        }
        amount
        distributed
        distributedThisYear
      }
    }
  }
`

export const USERS_QUERY = gql`
  query users($sortBy: UserSort, $filter: UserFilter, $limit: Int, $page: Int) {
    users(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        archivedAt
        archived
        repId
        name
        email
        phone
        mobile
        brandLines {
          id
          name
        }
        fieldManager
        isAreaManager
        superadmin
        translator
        brandManager
        rtb
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`

export const COMPANY_QUERY = gql`
  {
    company {
      id
      slug
      name
      createdAt
      updatedAt
    }
  }
`

export const ASSET_CONTENTS_QUERY = gql`
  query assetContents(
    $sortBy: AssetContentSort
    $filter: AssetContentFilter
    $limit: Int
    $page: Int
  ) {
    assetContents(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        archived
        assets {
          id
          brandLine {
            id
            name
          }
          amount
          distributed
        }
        brand {
          name
        }
        images {
          imageFileName
          authenticatedImageUrl
        }
        accessibleUntil
        createdAt
        description
        descriptionDe
        descriptionEn
        descriptionFr
        descriptionIt
        formattedPrice
        glassesModel
        shippingFrom
        isActive
        material
        materialDe
        materialEn
        materialFr
        materialIt
        needsMandatoryOrderInfo
        numberOfItems
        price
        productCode
        sendOrderInfoToLogisticCenter
        excludeFromOrderConfirmation
        sizes
        title
        titleDe
        titleEn
        titleFr
        titleIt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`

export const ASSET_CONTENT_QUERY = gql`
  query assetContent($id: ID!) {
    assetContent(id: $id) {
      id
      productCode
      brandId
      brand {
        id
        name
      }
      campaignId
      campaign {
        id
        title
      }
      title
      titleDe
      titleIt
      titleFr
      titleEn
      price
      sizes
      accessibleUntil
      shippingFrom
      glassesModel
      mandatoryInformation
      mandatoryInformationDe
      mandatoryInformationEn
      mandatoryInformationFr
      mandatoryInformationIt
      material
      materialDe
      materialEn
      materialIt
      materialFr
      description
      descriptionDe
      descriptionIt
      descriptionFr
      descriptionEn
      needsMandatoryOrderInfo
      sendOrderInfoToLogisticCenter
      excludeFromOrderConfirmation
      authenticatedDocumentUrl
      documentFileName
      documents {
        id
        documentFileName
        documentFileSize
        authenticatedDocumentUrl
      }
      authenticatedExcelUrl
      excelFileName
      images {
        id
        authenticatedImageUrl
        imageContentType
        imageFileName
      }
      assets {
        id
        mandatoryInformation
        title
        logisticCenter {
          id
          name
        }
        brandLine {
          id
          name
        }
      }
    }
  }
`
export const ASSET_CONTENT_AVAILABILITIES_QUERY = gql`
  query assetContentAvailabilities($id: ID!) {
    assetContent(id: $id) {
      id
      title
      availabilities {
        id
        assignableName
        assignableId
        assignableType
        allowed
        position
      }
    }
  }
`
export const ASSET_CONTENT_BRAND_LINES_QUERY = gql`
  query assetContentBrandLines($id: ID!) {
    assetContent(id: $id) {
      id
      title
      stock
      allocatedInBrandLines
      assets {
        id
        title
        brandLineId
        onHold
        repDistributionEnd
        amount
        logisticCenterId
        shippingDateToLogisticCenter
        distributed
        brandLine {
          id
          name
        }
        logisticCenter {
          name
        }
      }
    }
  }
`
export const ASSET_SALES_REPS_QUERY = gql`
  query assetSalesReps($id: ID!) {
    asset(id: $id) {
      id
      userRationings {
        id
        user {
          name
          id
        }
        amount
        distributed
        distributedThisYear
      }
    }
  }
`

export const CAMPAIGNS_QUERY = gql`
  query campaigns($filter: CampaignFilter, $sortBy: CampaignSort, $limit: Int, $page: Int) {
    campaigns(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        title
        position
        active
        endDate
        startDate
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`

export const COUNTRIES_ORDER_QUERY = gql`
  query countriesOrder($sortBy: CountrySort, $filter: CountryFilter, $limit: Int, $page: Int) {
    countries(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        name
        supportEmail
        imprint
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`

export const DROPDOWN_CAMPAIGNS_QUERY = gql`
  {
    campaignNames
  }
`

export const DROPDOWN_BRANDLINES_QUERY = gql`
  query dropdownBrandLines($sortBy: BrandLineSort) {
    brandLines(sortBy: $sortBy) {
      collection {
        id
        name
        users {
          id
          name
        }
      }
    }
  }
`

export const DROPDOWN_USERS_QUERY = gql`
  query dropdownUsers($sortBy: UserSort, $filter: UserFilter, $limit: Int) {
    users(sortBy: $sortBy, filter: $filter, limit: $limit) {
      collection {
        id
        repId
        name
        isAreaManager
        brandLines {
          id
          name
        }
      }
    }
  }
`

export const DROPDOWN_SEGMENTATIONS_QUERY = gql`
  query dropdownSegementations($sortBy: CustomerGroupSort) {
    customerGroups(sortBy: $sortBy) {
      collection {
        id
        name
      }
    }
  }
`

export const DROPDOWN_GTM_SEGMENTATIONS_QUERY = gql`
  query dropdownGtmSegmentations($sortBy: CustomerGtmGroupSort) {
    customerGtmGroups(sortBy: $sortBy) {
      collection {
        id
        name
      }
    }
  }
`

export const DROPDOWN_ESSILOR_SEGMENTATIONS_QUERY = gql`
  query dropdownEssilorSegmentations($sortBy: CustomerEssilorGroupSort) {
    customerEssilorGroups(sortBy: $sortBy) {
      collection {
        id
        name
      }
    }
  }
`

export const BRANDLINES_QUERY = gql`
  query brandLines($sortBy: BrandLineSort, $filter: BrandLineFilter, $limit: Int, $page: Int) {
    brandLines(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        name
        brand {
          id
          name
        }
        isArchived
        assetManagement
        hasPartnerPrograms
        countryId
        languages
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`

export const BRAND_LINE_QUERY = gql`
  query brandLine($id: ID!) {
    brandLine(id: $id) {
      id
      assetManagement
      brand {
        id
      }
      brandId
      countryId
      createdAt
      hasPartnerPrograms
      isArchived
      languages
      name
      relatedBrandLineId
      selectAssetsFromAllBrands
      updatedAt
    }
  }
`

export const CAMPAIGN_QUERY = gql`
  query campaign($id: ID!) {
    campaign(id: $id) {
      id
      title
      titleDe
      titleIt
      titleFr
      titleEn
      position
      endDate
      startDate
    }
  }
`

export const LOGISTIC_CENTERS_QUERY = gql`
  query logisticCenters(
    $sortBy: LogisticCenterSort
    $filter: LogisticCenterFilter
    $limit: Int
    $page: Int
  ) {
    logisticCenters(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        brandLines {
          id
          name
        }
        createdAt
        id
        name
        deliveryTime
        email
        email2
        email3
        email4
        email5
        emailInfo
        onHold
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`

export const CUSTOMERS_QUERY = gql`
  query customers($sortBy: CustomerSort, $filter: CustomerFilter, $page: Int, $limit: Int) {
    customers(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        customerNo
        essilorCustomerNo
        name
        street
        postzip
        city
        email
        phone
        customerGroups {
          id
          name
        }
        customerGtmGroups {
          id
          name
        }
        customerEssilorGroups {
          id
          name
        }
        brandLines {
          id
          name
        }
        users {
          firstName
          lastName
        }
        partnerPrograms {
          name
        }
        customerToPartnerPrograms {
          id
          createdAt
          isActive
          partnerProgram {
            name
          }
          initialBrandLine {
            name
          }
          activities {
            info
            createdAt
          }
        }

        userCaresCustomers {
          id
          user {
            id
            name
          }
          brandLine {
            id
            name
          }
        }
      }
      metadata {
        totalCount
        totalPages
        currentPage
        limitValue
      }
    }
  }
`
export const EXPORT_CUSTOMERS_QUERY = gql`
  query exportCustomers($sortBy: CustomerSort, $filter: CustomerFilter, $format: String!) {
    exportCustomers(sortBy: $sortBy, filter: $filter, format: $format)
  }
`

export const MERGE_CUSTOMERS_QUERY = gql`
  query mergeCustomers($filter: CustomerFilter, $page: Int, $limit: Int) {
    customerDuplicates(filter: $filter, page: $page, limit: $limit) {
      street
      postzip
      customers {
        id
        name
        contactPerson
        channelOfTrade
        city
        street
        postzip
        email
        phone
        mobile
        brandLines {
          id
          name
        }
        users {
          id
          name
        }
        customerGroups {
          name
        }
        customerGtmGroups {
          name
        }
      }
    }
  }
`

export const CUSTOMER_QUERY = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      brandLineIds
      brandLines {
        id
        name
      }
      channelOfTrade
      city
      contactPerson
      countryId
      createdAt
      customerGroupIds
      customerGroups {
        id
        name
      }
      customerGtmGroupIds
      customerGtmGroups {
        id
        name
      }
      customerEssilorGroupIds
      customerEssilorGroups {
        id
        name
      }
      customerNo
      essilorCustomerNo
      name
      duplicateCustomerId
      email
      fromImport
      ignoreInDulicateList
      lang
      mobile
      noMassOrders
      noMassOrdersSetDate
      phone
      postzip
      street
      updatedAt
      userIds
    }
  }
`

export const USER_CARES_CUSTOMER_BY_CUSTOMER_ID_QUERY = gql`
  query userCaresCustomersByCustomerId($customerId: ID!) {
    userCaresCustomersByCustomerId(customerId: $customerId) {
      collection {
        id
        user {
          id
          name
        }
        brandLine {
          id
          name
        }
      }
    }
  }
`

export const LOGISTIC_CENTER_QUERY = gql`
  query logisticCenter($id: ID!) {
    logisticCenter(id: $id) {
      id
      name
      brandLines {
        id
      }
      createdAt
      updatedAt
      deliveryTime
      email
      email2
      email3
      email4
      email5
      emailInfo
      onHold
    }
  }
`

export const COUNTRY_QUERY = gql`
  query country($id: ID!) {
    country(id: $id) {
      id
      name
      imprint
      supportEmail
    }
  }
`

export const COUNTRIES_QUERY = gql`
  {
    countries {
      collection {
        id
        name
      }
    }
  }
`

export const IPAD_TRANSLATIONS_BY_KEYS_QUERY = gql`
  query ipadTranslationsByKeys($keys: [String!]!) {
    ipadTranslationsByKeys(keys: $keys) {
      ipadTranslations {
        id
        key
        languageKey
        needUpdate
        translation
      }
      key
    }
  }
`

export const CUSTOMER_GROUPS_QUERY = gql`
  query customerGroups(
    $sortBy: CustomerGroupSort
    $filter: CustomerGroupFilter
    $page: Int
    $limit: Int
  ) {
    customerGroups(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        createdAt
        customersCount
        id
        name
        position
        updatedAt
      }
      metadata {
        totalCount
      }
    }
  }
`

export const CUSTOMER_ESSILOR_GROUPS_QUERY = gql`
  query customerEssilorGroups(
    $sortBy: CustomerEssilorGroupSort
    $filter: CustomerEssilorGroupFilter
    $page: Int
    $limit: Int
  ) {
    customerEssilorGroups(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        createdAt
        customersCount
        id
        name
        position
        updatedAt
      }
      metadata {
        totalCount
      }
    }
  }
`

export const CUSTOMER_GTM_GROUPS_QUERY = gql`
  query customerGtmGroups(
    $sortBy: CustomerGtmGroupSort
    $filter: CustomerGtmGroupFilter
    $page: Int
    $limit: Int
  ) {
    customerGtmGroups(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        createdAt
        customersCount
        id
        name
        position
        updatedAt
      }
      metadata {
        totalCount
      }
    }
  }
`

export const IPAD_TRANSLATIONS_QUERY = gql`
  query ipadTranslations(
    $page: Int
    $limit: Int
    $sortBy: IpadTranslationSort
    $filter: IpadTranslationFilter
  ) {
    ipadTranslations(page: $page, limit: $limit, sortBy: $sortBy, filter: $filter) {
      collection {
        id
        createdAt
        key
        languageKey
        needUpdate
        translation
        resourceId
        resourceType
      }
      metadata {
        totalCount
      }
    }
  }
`

export const IPAD_TRANSLATION_QUERY = gql`
  query ipadTranslation($id: ID) {
    ipadTranslation(id: $id) {
      id
      key
      languageKey
      translation
    }
  }
`

export const IPAD_TRANSLATIONS_BY_BRANDLINES_QUERY = gql`
  query ipadTranslationsByBrandLines(
    $sortBy: BrandLineSort
    $filter: BrandLineFilter
    $limit: Int
    $page: Int
  ) {
    brandLines(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        createdAt
        name
        ipadTranslations {
          id
          key
          languageKey
          translation
          resourceId
          resourceType
        }
        languages
      }
      metadata {
        totalCount
      }
    }
  }
`

export const IPAD_SETTINGS_QUERY = gql`
  {
    ipadVersion {
      id
      versionNumber
      downloadLink
    }
  }
`

export const BRANDS_QUERY = gql`
  query brands($sortBy: BrandSort, $filter: BrandFilter, $limit: Int, $page: Int) {
    brands(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        name
        primaryColor
        brandLinesCount
        authenticatedImageUrl
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`

export const BRAND_QUERY = gql`
  query brand($id: ID!) {
    brand(id: $id) {
      id
      name
      primaryColor
      authenticatedImageUrl
      headerImageFileName
    }
  }
`
export const ORDERS_QUERY = gql`
  query orders($sortBy: OrderSort, $filter: OrderFilter, $page: Int, $limit: Int) {
    orders(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        createdAt
        customerId
        brandLine {
          id
          name
        }
        customer {
          id
          name
        }
        userId
        user {
          id
          name
        }
        state

        orderedItems {
          id
          assetId
          unscopedAsset {
            assetContent {
              id
              productCode
              title
            }
          }
          alreadyShipped
          amount
        }
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`
export const EXPORT_ORDERS_QUERY = gql`
  query exportOrders(
    $sortBy: OrderSort
    $filter: OrderFilter
    $format: String!
    $markAsShipped: Boolean
  ) {
    exportOrders(sortBy: $sortBy, filter: $filter, format: $format, markAsShipped: $markAsShipped)
  }
`

export const ORDER_QUERY = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      state
      brandLineId
      createdAt
      customer {
        id
        channelOfTrade
        city
        contactPerson
        countryId
        customerGroupIds
        customerGtmGroupIds
        customerGroups {
          id
          name
        }
        customerGtmGroups {
          id
          name
        }
        customerNo
        email
        lang
        mobile
        name
        noMassOrders
        phone
        postzip
        street
        userIds
        fromImport
      }
      orderedItems {
        id
        assetId
        orderMessage
        sentToLogisticCenterAt
        unscopedAsset {
          assetContent {
            id
            productCode
            title
          }
          logisticCenter {
            updatedAt
            createdAt
          }
        }
        alreadyShipped
        amount
      }
      total
      updatedAt
      user {
        id
        name
        firstName
        lastName
        email
        mobile
        phone
        repId
      }
    }
  }
`

export const DROPDOWN_SEGMENTATION_QUERY = gql`
  query dropdownSegmentation(
    $sortBy: CustomerGroupSort
    $filter: CustomerGroupFilter
    $page: Int
    $limit: Int
  ) {
    customerGroups(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        name
      }
    }
  }
`

export const DROPDOWN_ESSILOR_SEGMENTATION_QUERY = gql`
  query dropdownEssilorSegmentation($sortBy: CustomerEssilorGroupSort, $limit: Int) {
    customerEssilorGroups(sortBy: $sortBy, limit: $limit) {
      collection {
        id
        name
      }
    }
  }
`

export const DROPDOWN_GTMSEGMENTATION_QUERY = gql`
  query dropdownGtmSegmentation($sortBy: CustomerGtmGroupSort, $limit: Int) {
    customerGtmGroups(sortBy: $sortBy, limit: $limit) {
      collection {
        id
        name
      }
    }
  }
`

export const ACTIVITIES_QUERY = gql`
  query activities(
    $base: ActivitiesBaseEnum
    $kind: ActivitiesKindEnum
    $areaManagerId: Int
    $userIds: [Int!]
    $assetIds: [Int!]
    $brandLineIds: [Int!]
    $campaignNames: [String!]
  ) {
    activities(
      base: $base
      kind: $kind
      userIds: $userIds
      assetIds: $assetIds
      areaManagerId: $areaManagerId
      brandLineIds: $brandLineIds
      campaignNames: $campaignNames
    ) {
      resourceName
      resourceId
      data {
        label
        total
        totalAmount
        subset {
          amount
          resourceTitle
        }
      }
    }
  }
`

export const DROPDOWN_ASSETS_QUERY = gql`
  query dropdownAssets($sortBy: AssetSort, $filter: AssetFilter, $limit: Int, $page: Int) {
    assets(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        title
      }
    }
  }
`

export const DROPDOWN_ASSET_CONTENTS_QUERY = gql`
  query dropdownAssetContents(
    $sortBy: AssetContentSort
    $filter: AssetContentFilter
    $limit: Int
    $page: Int
  ) {
    assetContents(sortBy: $sortBy, filter: $filter, limit: $limit, page: $page) {
      collection {
        id
        productCode
        title
      }
    }
  }
`

export const ACTIVITIES_OVERALL_QUERY = gql`
  query activitiesOverall(
    $base: ActivitiesBaseEnum
    $kind: ActivitiesKindEnum
    $userIds: [Int!]
    $assetIds: [Int!]
    $areaManagerId: Int
    $brandLineIds: [Int!]
    $campaignNames: [String!]
  ) {
    activitiesOverall(
      base: $base
      kind: $kind
      userIds: $userIds
      assetIds: $assetIds
      areaManagerId: $areaManagerId
      brandLineIds: $brandLineIds
      campaignNames: $campaignNames
    ) {
      resourceName
      resourceId
      data {
        label
        total
        totalAmount
        subset {
          amount
          resourceTitle
        }
      }
    }
  }
`
export const DROPDOWN_AVAILABLE_ASSETS_QUERY = gql`
  query dropdownAvailableAssets($userId: Int!, $brandLineId: Int!) {
    availableAssets(userId: $userId, brandLineId: $brandLineId) {
      id
      title
      remainingAmount
      productCode
    }
  }
`

export const DROPDOWN_CUSTOMERS_QUERY = gql`
  query dropdownCustomers($sortBy: CustomerSort, $limit: Int, $filter: CustomerFilter) {
    customers(sortBy: $sortBy, limit: $limit, filter: $filter) {
      collection {
        id
        name
        brandLines {
          id
          name
        }
      }
    }
  }
`

export const REPORTS_ASSETS_QUERY = gql`
  query reportsAssets(
    $sortBy: AssetDistributionSort
    $filter: AssetDistributionFilter
    $page: Int
    $limit: Int
  ) {
    assetDistribution(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        productCode
        title
        authenticatedDocumentUrl
        formattedPrice
        price
        sizes
        material
        numberOfItems
        description
        mandatoryInformation
        glassesModel
        accessibleUntil
        shippingFrom
        needsMandatoryOrderInfo
        sendOrderInfoToLogisticCenter
        excludeFromOrderConfirmation
        stock
        allocation
        distributed
        distributedThisYear
        assets {
          assetContent {
            images {
              authenticatedImageUrl
            }
          }
        }
        brand {
          id
          name
          authenticatedImageUrl
        }
        customerGroupDistribution {
          resourceId
          resourceClass
          resourceTitle
          distributed
        }
        customerGtmGroupDistribution {
          resourceId
          resourceClass
          resourceTitle
          distributed
        }
      }
      metadata {
        totalCount
      }
    }
  }
`
export const EXPORT_REPORTS_ASSETS_QUERY = gql`
  query exportAssetDistribution(
    $sortBy: AssetDistributionSort
    $filter: AssetDistributionFilter
    $format: String!
  ) {
    exportAssetDistribution(sortBy: $sortBy, filter: $filter, format: $format)
  }
`

export const REPORTS_ASSET_REP_DETAIL_QUERY = gql`
  query reportsAssetRepDetail($filter: OrderedItemFilter) {
    orderedItems(filter: $filter) {
      collection {
        order {
          customer {
            name
          }
        }
        createdAt
        amount
      }
    }
  }
`

export const REPORTS_ASSET_TITLE_QUERY = gql`
  query reportsAsetTitle($id: ID!) {
    assetContent(id: $id) {
      id
      title
    }
  }
`

export const REPORTS_ASSET_DETAIL_QUERY = gql`
  query reportsAssetDetail($id: ID!) {
    asset(id: $id) {
      id
      price
      brandLine {
        name
      }
      userRationings {
        id
        amount
        distributed
        distributedThisYear
        user {
          id
          name
        }
      }
    }
  }
`

export const REPORTS_ASSET_DISTRIBUTION_QUERY = gql`
  query reportsAssetDistribution($id: ID!) {
    assetContent(id: $id) {
      id
      userRationings {
        id
        user {
          id
          name
        }
        asset {
          price
          brandLine {
            name
          }
        }
        amount
        distributed
        distributedThisYear
      }
    }
  }
`

export const REPORTS_ASSET_ALLOCATION_QUERY = gql`
  query reportsAssetAllocation($id: ID!) {
    assetContent(id: $id) {
      id
      title
      assets {
        id
        amount
        distributedThisYear
        distributed
        allocation
        price
        brandLine {
          name
        }
      }
    }
  }
`

export const REPORTS_CUSTOMER_QUERY = gql`
  query reportsCustomer($id: ID!) {
    customer(id: $id) {
      id
      name
      customerNo
    }
  }
`

export const REPORTS_CUSTOMER_DETAIL_QUERY = gql`
  query reportsCustomerDetail($filter: OrderedItemFilter, $sortBy: OrderedItemSort) {
    orderedItems(filter: $filter, sortBy: $sortBy) {
      collection {
        id
        orderId
        asset {
          title
          brandLine {
            name
          }
        }
        createdAt
        amount
        total
        order {
          id
          user {
            name
          }
          customer {
            name
          }
          customerId
        }
      }
    }
  }
`

export const REPORTS_CUSTOMERS_QUERY = gql`
  query reportsCustomers(
    $sortBy: CustomerDistributionSort
    $filter: CustomerDistributionFilter
    $page: Int
    $limit: Int
  ) {
    customerDistribution(filter: $filter, sortBy: $sortBy, page: $page, limit: $limit) {
      collection {
        id
        customerNo
        name
        street
        postzip
        city
        country {
          id
          name
        }
        brandLines {
          id
          name
        }
        users {
          id
          name
        }
        contactPerson
        email
        phone
        mobile
        customerGroups {
          id
          name
        }
        customerGtmGroups {
          id
          name
        }
        partnerPrograms {
          id
          name
        }
        lang
        received
        receivedInEuro
        receivedThisYear
        receivedThisYearInEuro
        receivedLastYear
        receivedLastYearInEuro
      }
      metadata {
        limitValue
        currentPage
        totalPages
      }
    }
  }
`

export const EXPORT_REPORTS_CUSTOMERS_QUERY = gql`
  query exportCustomerDistribution(
    $sortBy: CustomerDistributionSort
    $filter: CustomerDistributionFilter
    $format: String!
  ) {
    exportCustomerDistribution(filter: $filter, sortBy: $sortBy, format: $format)
  }
`

export const REPORTS_SALES_REPS_QUERY = gql`
  query reportsSalesReps(
    $sortBy: UserDistributionSort
    $filter: UserDistributionFilter
    $areaManagerId: Int
    $page: Int
    $limit: Int
    $assetContentIds: [Int!]
  ) {
    userDistribution(
      sortBy: $sortBy
      filter: $filter
      areaManagerId: $areaManagerId
      page: $page
      limit: $limit
      assetContentIds: $assetContentIds
    ) {
      collection {
        repId
        id
        firstName
        lastName
        email
        phone
        mobile
        brandLineCustomers {
          name
          customersCount
        }
        customerGroupDistribution {
          resourceId
          resourceTitle
          distributed
        }
        customerGtmGroupDistribution {
          resourceId
          resourceTitle
          distributed
        }
        rationings {
          id
          distributed
          distributedThisYear
          amount
          asset {
            id
            title
            assetContent {
              id
              campaign {
                title
              }
            }
            brandLine {
              name
            }
          }
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export const EXPORT_REPORTS_SALES_REPS_QUERY = gql`
  query exportUserDistribution(
    $sortBy: UserDistributionSort
    $filter: UserDistributionFilter
    $assetContentIds: [Int!]
    $format: String!
  ) {
    exportUserDistribution(
      sortBy: $sortBy
      filter: $filter
      assetContentIds: $assetContentIds
      format: $format
    )
  }
`

export const CAMPAIGN_OVERALL_STATISTIC_QUERY = gql`
  query campaignOverallStatistic(
    $kind: CampaignStatisticsKindEnum
    $userIds: [Int!]
    $brandLineIds: [Int!]
    $assetIds: [Int!]
    $areaManagerId: Int
    $customerGroupIds: [Int!]
    $customerGtmGroupIds: [Int!]
    $campaignNames: [String!]
  ) {
    campaignStatisticOverall(
      kind: $kind
      userIds: $userIds
      assetIds: $assetIds
      areaManagerId: $areaManagerId
      brandLineIds: $brandLineIds
      customerGroupIds: $customerGroupIds
      customerGtmGroupIds: $customerGtmGroupIds
      campaignNames: $campaignNames
    ) {
      resourceName
      resourceId
      data {
        label
        totalAmount
        distributed
      }
    }
  }
`

export const CAMPAIGN_STATISTIC_QUERY = gql`
  query capaignStatistic(
    $kind: CampaignStatisticsKindEnum
    $userIds: [Int!]
    $assetIds: [Int!]
    $areaManagerId: Int
    $brandLineIds: [Int!]
    $customerGroupIds: [Int!]
    $customerGtmGroupIds: [Int!]
    $campaignNames: [String!]
  ) {
    campaignStatistic(
      kind: $kind
      userIds: $userIds
      assetIds: $assetIds
      areaManagerId: $areaManagerId
      brandLineIds: $brandLineIds
      customerGroupIds: $customerGroupIds
      customerGtmGroupIds: $customerGtmGroupIds
      campaignNames: $campaignNames
    ) {
      resourceName
      resourceId
      data {
        label
        totalAmount
        distributed
      }
    }
  }
`

export const SEGMENTATION_OVERALL_STATISTIC_QUERY = gql`
  query segmentationOverallStatistic(
    $base: CustomerGroupStatisticsBaseEnum
    $kind: CustomerGroupStatisticsKindEnum
    $userIds: [Int!]
    $assetIds: [Int!]
    $areaManagerId: Int
    $brandLineIds: [Int!]
    $campaignNames: [String!]
  ) {
    customerGroupStatisticsOverall(
      base: $base
      kind: $kind
      userIds: $userIds
      assetIds: $assetIds
      areaManagerId: $areaManagerId
      brandLineIds: $brandLineIds
      campaignNames: $campaignNames
    ) {
      resourceName
      resourceId
      data {
        label
        resourceId
        totalAmount
        distributed
        subset {
          amount
          resourceTitle
        }
      }
    }
  }
`

export const SEGMENTATION_STATISTIC_QUERY = gql`
  query segmentationStatistic(
    $base: CustomerGroupStatisticsBaseEnum
    $kind: CustomerGroupStatisticsKindEnum
    $userIds: [Int!]
    $assetIds: [Int!]
    $areaManagerId: Int
    $brandLineIds: [Int!]
    $campaignNames: [String!]
  ) {
    customerGroupStatistics(
      base: $base
      kind: $kind
      userIds: $userIds
      assetIds: $assetIds
      areaManagerId: $areaManagerId
      brandLineIds: $brandLineIds
      campaignNames: $campaignNames
    ) {
      resourceName
      resourceId
      data {
        label
        resourceId
        totalAmount
        distributed
        subset {
          amount
          resourceTitle
        }
      }
    }
  }
`

export const BACKGROUND_JOBS_QUERY = gql`
  query backgroundJobs(
    $filter: BackgroundTaskFilter
    $sortBy: BackgroundTaskSort
    $page: Int
    $limit: Int
  ) {
    backgroundTasks(filter: $filter, sortBy: $sortBy, page: $page, limit: $limit) {
      collection {
        status
        type
        createdAt
        user {
          id
          name
        }
        authenticatedDocumentUrl
        brandLine {
          id
          name
        }
        errorMessage
        hasErrors
        id
        inquiryId
        partnerProgramId
      }
      metadata {
        totalCount
      }
    }
  }
`

export const GET_PRESIGNED_URL = gql`
  query getPresignedS3Url($filename: String!) {
    getPresignedS3Url(filename: $filename)
  }
`

export const TODAYS_ORDER_EXPORT_QUERY = gql`
  query todaysOrderExport(
    $sortBy: OrderedItemSort
    $filter: OrderedItemFilter
    $page: Int
    $limit: Int
  ) {
    todaysOrderExportToLogisticCenters(
      sortBy: $sortBy
      filter: $filter
      page: $page
      limit: $limit
    ) {
      collection {
        id
        updatedAt
        createdAt
        alreadyShipped
        amount
        orderId
        sentToLogisticCenterAt
        total
        asset {
          title
          amount
          logisticCenter {
            name
          }
        }
        order {
          id
          customer {
            id
            customerNo
            name
            street
            postzip
            city
            phone
          }
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export const ITEMS_ON_HOLD_QUERY = gql`
  query itemsOnHold(
    $limit: Int
    $page: Int
    $sortBy: OrderedItemSort
    $filter: OrderedItemFilter
    $onHoldReason: OnHoldReasonEnum
  ) {
    orderedItemsOnHold(
      limit: $limit
      page: $page
      sortBy: $sortBy
      filter: $filter
      onHoldReason: $onHoldReason
    ) {
      collection {
        id
        title
        onHold
        amount
        logisticCenter {
          id
          name
        }
        orders {
          id
          createdAt
          amountOverall
          customer {
            id
            name
            street
            postzip
            city
            phone
          }
          orderedItems {
            id
            assetId
            onHoldReason
            asset {
              id
            }
          }
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export const DROPDOWN_BRANDS_QUERY = gql`
  query dropdownBrands($sortBy: BrandSort) {
    brands(sortBy: $sortBy) {
      collection {
        id
        name
      }
    }
  }
`

export const DROPDOWN_CAMPAIGN_QUERY = gql`
  query dropdownCampaign($sortBy: CampaignSort) {
    campaigns(sortBy: $sortBy) {
      collection {
        id
        title
      }
    }
  }
`

export const DROPDOWN_LOGISTIC_CENTERS_QUERY = gql`
  query dropdownLogisticCenters($sortBy: LogisticCenterSort) {
    logisticCenters(sortBy: $sortBy) {
      collection {
        id
        name
      }
    }
  }
`

export const DROPDOWN_COUNTRIES_QUERY = gql`
  query dropdownCountries($sortBy: CountrySort) {
    countries(sortBy: $sortBy) {
      collection {
        id
        name
      }
      metadata {
        currentPage
        totalCount
        limitValue
        totalPages
      }
    }
  }
`
export const PARTNER_PROGRAMS_QUERY = gql`
  query partnerPrograms(
    $sortBy: PartnerProgramSort
    $filter: PartnerProgramFilter
    $page: Int
    $limit: Int
  ) {
    partnerPrograms(sortBy: $sortBy, filter: $filter, page: $page, limit: $limit) {
      collection {
        id
        name
        activeCustomersCount
        inactiveCustomersCount
        isArchived
        activeInquiry {
          id
          name
        }
        activeInquiries {
          id
          name
        }
        brandLines {
          id
          name
        }
      }
      metadata {
        currentPage
        totalPages
        totalCount
        limitValue
      }
    }
  }
`
export const PARTNER_PROGRAM_QUERY = gql`
  query partnerProgram($id: ID!) {
    partnerProgram(id: $id) {
      id
      hasInitialInquiry
      name
      nameDe
      nameEn
      nameFr
      nameIt
      description
      descriptionDe
      descriptionEn
      descriptionFr
      descriptionIt
      subscribeEmailSubject
      subscribeEmailSubjectDe
      subscribeEmailSubjectEn
      subscribeEmailSubjectFr
      subscribeEmailSubjectIt
      subscribeEmailContent
      subscribeEmailContentDe
      subscribeEmailContentEn
      subscribeEmailContentFr
      subscribeEmailContentIt
      unsubscribeEmailSubject
      unsubscribeEmailSubjectDe
      unsubscribeEmailSubjectEn
      unsubscribeEmailSubjectFr
      unsubscribeEmailSubjectIt
      unsubscribeEmailContent
      unsubscribeEmailContentDe
      unsubscribeEmailContentEn
      unsubscribeEmailContentFr
      unsubscribeEmailContentIt
      isSubscriptionEmailActive
      isUnsubscriptionEmailActive
      notifyAdminsForChanges
      notifyAdminsForCustomerChanges
      startDate
      endDate
      activeCustomersCount
      inactiveCustomersCount
      isScheduledExportActive
      emailsForExport
      interval
      day
      wday
      emailsForNotifications
      inquiries {
        id
        name
        nameDe
        nameEn
        nameFr
        nameIt
        subscribeEmailContent
        subscribeEmailContentDe
        subscribeEmailContentEn
        subscribeEmailContentFr
        subscribeEmailContentIt
        subscribeEmailSubject
        subscribeEmailSubjectDe
        subscribeEmailSubjectEn
        subscribeEmailSubjectFr
        subscribeEmailSubjectIt
        startDate
        endDate
        isInitial
        isSubscriptionEmailActive
        subscribeEmailContent
        active
      }
      brandLineIds
      brandLines {
        id
        name
      }
      isArchived
      category
    }
  }
`
export const CUSTOMER_TO_PARTNER_PROGRAM_CONNECTIONS_QUERY = gql`
  query customerTospartnerProgramConnections(
    $id: String!
    $filter: CustomerToPartnerProgramConnectionFilter
    $sortBy: CustomerToPartnerProgramConnectionSort
    $page: Int
    $limit: Int
  ) {
    customerToPartnerProgramConnections(
      partnerProgramId: $id
      filter: $filter
      sortBy: $sortBy
      page: $page
      limit: $limit
    ) {
      metadata {
        currentPage
        totalCount
      }
      collection {
        id
        isActive
        partnerProgramId
        partnerProgram {
          id
          name
          inquiries {
            id
            name
            isInitial
          }
        }
        customerId
        customer {
          id
          customerNo
          name
          country {
            id
            name
          }
          brandLines {
            id
            name
          }
        }
        initialBrandLineId
        initialBrandLine {
          id
        }
        activities {
          info
        }
      }
    }
  }
`
export const EXPORT_CUSTOMER_TO_PARTNER_PROGRAM_CONNECTIONS_QUERY = gql`
  query exportCustomerTospartnerProgramConnections(
    $id: String!
    $format: String!
    $sortBy: CustomerToPartnerProgramConnectionSort
    $filter: CustomerToPartnerProgramConnectionFilter
  ) {
    exportCustomerToPartnerProgramConnections(
      partnerProgramId: $id
      format: $format
      sortBy: $sortBy
      filter: $filter
    )
  }
`

export const INQUIRY_QUERY = gql`
  query inquiry($id: ID!) {
    inquiry(id: $id) {
      id
      name
      startDate
      endDate
      nameDe
      nameEn
      nameFr
      nameIt
      active
      partnerProgram {
        id
        name
      }
      participants {
        id
        customerNo
        name
        street
        postzip
        city
        contactPerson
        brandLines {
          id
          name
        }
        customerToPartnerPrograms {
          id
          isActive
          partnerProgramId
          partnerProgram {
            inquiries {
              id
              name
            }
          }
        }
      }
      questions {
        id
      }
      isSubscriptionEmailActive
      subscribeEmailSubject
      subscribeEmailSubjectDe
      subscribeEmailSubjectEn
      subscribeEmailSubjectFr
      subscribeEmailSubjectIt
      subscribeEmailContent
      subscribeEmailContentDe
      subscribeEmailContentEn
      subscribeEmailContentFr
      subscribeEmailContentIt
      isInitial
    }
  }
`

export const QUESTIONS_ANSWERS_QUERY = gql`
  query questionAnswers($inquiryId: Int!, $customerId: Int!) {
    questionAnswers(inquiryId: $inquiryId, customerId: $customerId) {
      collection {
        id
        answerNumber
        answerOptionIds
        answerText
        answerToBinaryQuestion
        questionId
        userId
        userName
      }
    }
  }
`

export const QUESTIONS_QUERY = gql`
  query questions($id: Int!) {
    questions(inquiryId: $id) {
      collection {
        id
        inquiryId
        questionText
        questionTextDe
        questionTextEn
        questionTextFr
        questionTextIt
        type
        isIncludedInSubscribeMail
        answerOptions {
          id
          answerText
          answerTextDe
          answerTextEn
          answerTextFr
          answerTextIt
        }
        minDate
        maxDate
        minValue
        maxValue
      }
    }
  }
`
export const EXPORT_INQUIRY_QUERY = gql`
  query ($id: ID!, $format: String!, $filter: CustomerToPartnerProgramConnectionFilter) {
    exportInquiry(id: $id, format: $format, filter: $filter)
  }
`

export const EXPORT_ORDERED_ITEMS_ON_HOLD_QUERY = gql`
  query (
    $sortBy: OrderedItemSort
    $format: String!
    $filter: OrderedItemFilter
    $limit: Int
    $page: Int
    $markAsShipped: Boolean
  ) {
    exportOrderedItemsOnHold(
      sortBy: $sortBy
      format: $format
      filter: $filter
      limit: $limit
      page: $page
      markAsShipped: $markAsShipped
    )
  }
`
