import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Breadcrumb as AntBreadcrumb } from 'antd'
import { FilterPortal } from '../../atoms'
import { PARTNER_PROGRAM_QUERY, INQUIRY_QUERY, CUSTOMER_QUERY } from './queries'

const Breadcrumbs = ({ match }) => {
  const { path, params, url } = match
  const { t } = useTranslation('common')

  const [crumbs, setCrumbs] = useState({})
  useQuery(PARTNER_PROGRAM_QUERY, {
    skip: !params?.id,
    variables: { id: params?.id },
    onCompleted: data => setCrumbs(c => ({ ...c, id: data?.partnerProgram?.name })),
  })
  useQuery(INQUIRY_QUERY, {
    skip: !params?.inquiryId,
    variables: { id: params?.inquiryId },
    onCompleted: data => setCrumbs(c => ({ ...c, inquiryId: data?.inquiry?.name })),
  })
  useQuery(CUSTOMER_QUERY, {
    skip: !params?.customerId,
    variables: { id: params?.customerId },
    onCompleted: data => setCrumbs(c => ({ ...c, customerId: data?.customer?.name })),
  })

  const populateBreadcrumbs = () => {
    const sections = path.split('/').slice(2)
    const subPaths = url.split('/')
    return sections.map((section, index) => {
      let name
      let clickable = false
      if (section?.[0] === ':') {
        name = crumbs[section.substr(1, section.length - 1)]
        clickable = true
      } else {
        name = section
      }
      if (index === 0) clickable = true
      const href = subPaths.slice(0, index + 3).join('/')
      return { name, href, clickable }
    })
  }

  const renderElement = item => (
    <AntBreadcrumb.Item
      className={!item.clickable && 'normal-cursor'}
      href={item.clickable && item.href}
    >
      {t(item.name)}
    </AntBreadcrumb.Item>
  )
  return (
    <FilterPortal>
      <AntBreadcrumb separator=">">{populateBreadcrumbs()?.map(renderElement)}</AntBreadcrumb>
    </FilterPortal>
  )
}
Breadcrumbs.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(Breadcrumbs)
