import { lazy } from 'react'

export default [
  // Dashboards
  {
    path: '/dashboards/activity',
    Component: lazy(() => import('views/dashboards/Activity')),
    exact: true,
    secured: true,
  },
  {
    path: '/dashboards/campaigns',
    Component: lazy(() => import('views/dashboards/Campaigns')),
    exact: true,
    secured: true,
  },
  {
    path: '/dashboards/segmentation',
    Component: lazy(() => import('views/dashboards/Segmentation')),
    exact: true,
    secured: true,
  },
  {
    path: '/reports/assets',
    Component: lazy(() => import('views/reports/Assets')),
    exact: true,
    secured: true,
  },
  {
    path: '/reports/assets/:id',
    Component: lazy(() => import('views/reports/Assets/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/reports/customers',
    Component: lazy(() => import('views/reports/Customers')),
    exact: true,
    secured: true,
  },
  {
    path: '/reports/customers/:id',
    Component: lazy(() => import('views/reports/Customers/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/reports/sales-reps',
    Component: lazy(() => import('views/reports/SalesReps')),
    exact: true,
    secured: true,
  },
  {
    path: '/reports/sales-reps/:id',
    Component: lazy(() => import('views/reports/SalesReps/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/asset-settings/asset-management',
    Component: lazy(() => import('views/assetSettings/AssetManagement')),
    exact: true,
    secured: true,
  },
  {
    path: '/asset-settings/asset-management/:id',
    Component: lazy(() => import('views/assetSettings/AssetManagement/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/asset-settings/logistic-centers',
    Component: lazy(() => import('views/assetSettings/LogisticCenters')),
    exact: true,
    secured: true,
  },
  {
    path: '/asset-settings/logistic-centers/:id',
    Component: lazy(() => import('views/assetSettings/LogisticCenters/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/orders/orders',
    Component: lazy(() => import('views/orders/Orders')),
    exact: true,
    secured: true,
  },
  {
    path: '/orders/orders/:id',
    Component: lazy(() => import('views/orders/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/orders/mass-orders',
    Component: lazy(() => import('views/orders/MassOrders')),
    exact: true,
    secured: true,
  },
  {
    path: '/orders/todays-export',
    Component: lazy(() => import('views/orders/TodaysExport')),
    exact: true,
    secured: true,
  },
  {
    path: '/orders/items-on-hold',
    Component: lazy(() => import('views/orders/ItemsOnHold')),
    exact: true,
    secured: true,
  },
  {
    path: '/customers/customers',
    Component: lazy(() => import('views/customers/Customers')),
    exact: true,
    secured: true,
  },
  {
    path: '/customers/customers/:id',
    Component: lazy(() => import('views/customers/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/customers/merge-customers',
    Component: lazy(() => import('views/customers/MergeCustomers')),
    exact: true,
    secured: true,
  },
  {
    path: '/customers/customers-allocation',
    Component: lazy(() => import('views/customers/CustomersAllocation')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/brands',
    Component: lazy(() => import('views/administration/Brands')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/brands/:id',
    Component: lazy(() => import('views/administration/Brands/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/brandlines',
    Component: lazy(() => import('views/administration/BrandLines')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/brandlines/:id',
    Component: lazy(() => import('views/administration/BrandLines/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/essilor-cluster',
    Component: lazy(() => import('views/administration/EssilorSegmentation')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/gtm-segmentation',
    Component: lazy(() => import('views/administration/GTMSegmentation')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/segmentation',
    Component: lazy(() => import('views/administration/Segmentation/Segmentation')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/user-management',
    Component: lazy(() => import('views/administration/UserManagement')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/user-management/:id',
    Component: lazy(() => import('views/administration/UserManagement/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/partner-programs',
    Component: lazy(() => import('views/administration/PartnerPrograms')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/partner-programs/:id',
    Component: lazy(() => import('views/administration/PartnerPrograms/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/partner-programs/:id/inquiries/:inquiryId',
    Component: lazy(() => import('views/administration/PartnerPrograms/Detail/Inquiries/Inquiry')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/partner-programs/:id/inquiries/:inquiryId/question-answers/:customerId',
    Component: lazy(() =>
      import('views/administration/PartnerPrograms/Detail/Inquiries/Inquiry/QuestionAnswers')
    ),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/countries',
    Component: lazy(() => import('views/administration/Countries/Countries')),
    exact: true,
    secured: true,
  },
  {
    path: '/internationalisation/ipad-translations',
    Component: lazy(() => import('views/internationalisation/IPadTranslations')),
    exact: true,
    secured: true,
  },
  {
    path: '/administration/countries/:id',
    Component: lazy(() => import('views/administration/Countries/Detail')),
    exact: true,
    secured: true,
  },
  {
    path: '/internationalisation/order-confirmation',
    Component: lazy(() => import('views/internationalisation/OrderConfirmation')),
    exact: true,
    secured: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('views/auth/Login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('views/auth/ForgotPassword')),
    exact: true,
  },
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('views/auth/ResetPassword')),
    exact: false,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('views/auth/Register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('views/auth/Lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('views/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('views/auth/500')),
    exact: true,
  },
]
