import React, { useState, useEffect, useLayoutEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from '@zauberware/react-i18n/web'
import { Input, Button, notification, Tag, Select } from 'antd'
import { uniqueId } from 'lodash'
import { LIMIT } from '../services/graphql/queries'
import { getValueFromLocalStorage, writeToLocalStorage } from '.'

export const useTableFilterAndSort = ({
  fetchQuery,
  exportQueryString,
  limit = LIMIT,
  defaultSort = { attribute: 'createdAt', direction: 'asc' },
  defaultFilter,
  defaultAssetContentIds,
  onRenderTags,
  variables: propVariables,
  filterStorageKey,
}) => {
  const { t } = useTranslation('tableEssentials')
  const [filter, setFilter] = useState(
    defaultFilter || filterStorageKey ? getValueFromLocalStorage(filterStorageKey) : null
  )
  const [localFilter, setLocalFilter] = useState(null)

  const [assetContentIds, setAssetContentIds] = useState(defaultAssetContentIds)
  const [totalCount, setTotalCount] = useState(200)
  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(limit)
  const [sort, setSort] = useState(defaultSort)
  const [textSearch, setTextSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [variables, setVariables] = useState(propVariables)
  const [exportId, setExportId] = useState(null)
  const refetch = () =>
    fetchQuery({
      variables: {
        ...variables,
        sortBy: sort,
        limit: itemsPerPage,
        page,
        filter,
        assetContentIds,
      },
    })

  useEffect(() => {
    if (fetchQuery) {
      setLoading(true)
      fetchQuery({
        variables: {
          ...variables,
          sortBy: sort,
          limit: itemsPerPage,
          page,
          filter,
          assetContentIds,
        },
      })
    }
  }, [itemsPerPage, page, sort, filter, assetContentIds, fetchQuery, variables])

  const [tags, setTags] = useState([])
  useEffect(() => {
    if (filter) {
      const curFilters = Object.keys(filter)
      const activeFilters = curFilters
        .filter(key => filter[key] !== null)
        .map(key => ({
          filterName: key,
          name: filter[key],
          id: filter[key],
        }))
      setTags(activeFilters)
    }
  }, [filter])

  const removeFilter = ({ filterName }) => handleReset(filterName, false)

  const renderTags = () => {
    if (onRenderTags) return onRenderTags(tags)
    return tags.map(tag => {
      let tagContent = ''
      switch (tag.filterName) {
        case 'archived':
          tagContent = filter.archived ? t('archived') : t('notArchived')
          break

        default:
          tagContent = `${t(tag.filterName)}: ${tag.name}`
          break
      }
      return (
        <Tag closable onClose={() => removeFilter(tag)} key={uniqueId()}>
          {tagContent}
        </Tag>
      )
    })
  }

  const handleSearch = (dataIndex, selectedKeys, confirm, castType) => {
    if (confirm) confirm()
    let newFilter
    if (dataIndex && selectedKeys) {
      if (Array.isArray(selectedKeys)) {
        newFilter = { [dataIndex]: castType ? Number(selectedKeys[0]) : selectedKeys[0] }
      } else {
        newFilter = { [dataIndex]: castType ? Number(selectedKeys) : selectedKeys }
      }
    } else {
      newFilter = { textSearch }
    }
    setFilterAndStore(() => ({ ...newFilter }))
    setPage(1)
  }
  const handleReset = (dataIndex, clearFilters) => {
    const oldFilter = getValueFromLocalStorage(filterStorageKey)
    if (clearFilters) clearFilters()
    if (dataIndex) {
      const filterObject = { ...oldFilter }
      delete filterObject[dataIndex]
      resetFilterAndStore(filterObject)
      setPage(1)
    } else {
      resetFilterAndStore()
      setPage(1)
      setTextSearch('')
    }
  }
  const resetFilterAndStore = newFilter => {
    if (newFilter) {
      writeToLocalStorage(filterStorageKey, newFilter)
      setFilter(newFilter)
    } else {
      localStorage.removeItem(filterStorageKey)
      setFilter({})
    }
  }
  const onChange = (pagination, filters, sorter) => {
    if (page !== pagination.current) {
      setPage(pagination.current)
    } else {
      if (itemsPerPage !== pagination.pageSize) {
        setItemsPerPage(pagination.pageSize)
      } else if (filters) {
        const f = {}
        if (Object.values(filters) && Object.values(filters).find(v => v !== null)) {
          Object.keys(filters).forEach(key => {
            if (Array.isArray(filters[key])) {
              if (filters[key].join('') === 'true') {
                f[key] = true
              } else if (filters[key].join('') === 'false') {
                f[key] = false
              } else {
                f[key] = filters[key].join('')
              }
            } else {
              f[key] = filters[key]
            }
          })
          if (f !== filter) {
            setFilterAndStore(f)
            setPage(1)
          }
        }
      }

      let attribute
      if (sorter.columnKey === 'brand') {
        attribute = 'brandId'
      } else if (sorter.columnKey === 'user') {
        attribute = 'userId'
      } else if (sorter.columnKey === 'customer') {
        attribute = 'customerId'
      } else if (!sorter.columnKey) {
        attribute = 'createdAt'
      } else {
        attribute = sorter.columnKey
      }
      const direction = sort.attribute === attribute && sort.direction === 'asc' ? 'desc' : 'asc'

      const newSort = {
        attribute,
        direction,
      }
      // TODO: filter and sort reset on each call
      if (!sorter?.column?.localSort && sort !== newSort) {
        setSort(newSort)
      }
    }
  }

  const setColumnSearchDrop = (dataIndex, dataArr, castType) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ confirm, clearFilters }) => {
      const { Option } = Select
      return (
        <div style={{ padding: 8 }}>
          <Select
            onChange={e => handleSearch(dataIndex, e, confirm, castType)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          >
            {dataArr.map(item => (
              <Option value={item?.value}>{item?.label || item?.value}</Option>
            ))}
          </Select>
          <Button
            onClick={e => {
              e.preventDefault()
              handleReset(dataIndex, clearFilters)
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t('reset', { defaultValue: 'Reset' })}
          </Button>
        </div>
      )
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    filteredValue: filter ? filter[dataIndex] : null,
  })

  const getColumnSearchProps = (dataIndex, castType) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${t('search')} ${dataIndex}`}
          value={Array.isArray(selectedKeys) ? selectedKeys[0] : selectedKeys}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(dataIndex, selectedKeys, confirm, castType)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={e => {
            e.preventDefault()
            handleSearch(dataIndex, selectedKeys, confirm, castType)
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('search')}
        </Button>
        <Button
          onClick={e => {
            e.preventDefault()
            handleReset(dataIndex, clearFilters)
          }}
          size="small"
          style={{ width: 90 }}
        >
          {t('reset', { defaultValue: 'Reset' })}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    filteredValue: filter ? filter[dataIndex] : null,
  })

  const getColumnLocalSearchProps = dataIndex => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${t('search')} ${dataIndex}`}
          allowClear
          value={Array.isArray(selectedKeys) ? selectedKeys[0] : selectedKeys}
          onChange={e => {
            setLocalFilter(e.target.value)
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8 }}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('search')}
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          {t('reset', { defaultValue: 'Reset' })}
        </Button>
      </div>
    ),
    filterIcon: () => <SearchOutlined style={{ color: localFilter ? '#1890ff' : undefined }} />,
  })
  let exportQuery = () => {}

  if (exportQueryString) {
    // eslint-disable-next-line
    exportQuery = useLazyQuery(exportQueryString, {
      fetchPolicy: 'network-only',
      onCompleted: () =>
        notification.success({ message: t('common:success'), description: t('exportStarted') }),
      onError: err => console.error('err', err),
    })[0]
  }

  const onExportWithCheckbox = (format, markAsShipped) => {
    if (exportId) {
      exportQuery({
        variables: {
          ...variables,
          id: exportId,
          filter,
          sortBy: sort,
          format,
          markAsShipped,
        },
      })
    } else {
      exportQuery({
        variables: {
          ...variables,
          filter,
          sortBy: sort,
          format,
          markAsShipped,
        },
      })
    }
  }

  const onExport = format => {
    if (exportId) {
      exportQuery({
        variables: {
          ...variables,
          id: exportId,
          filter,
          sortBy: sort,
          format,
        },
      })
    } else {
      exportQuery({
        variables: {
          ...variables,
          filter,
          sortBy: sort,
          format,
        },
      })
    }
  }

  const setFilterAndStore = f => {
    if (filterStorageKey) {
      const oldValue = getValueFromLocalStorage(filterStorageKey)
      const newFilter = typeof f === 'function' ? f() : f
      const filterToSet = { ...oldValue, ...newFilter }
      const keys = Object.keys(filterToSet)
      keys.forEach(key => {
        if (filterToSet?.[key] === null) {
          delete filterToSet[key]
        }
      })
      writeToLocalStorage(filterStorageKey, filterToSet)
      setFilter(filterToSet)
    } else {
      setFilter(f)
    }
  }

  const setFilterAndResetPage = f => {
    setFilterAndStore(f)
    setPage(1)
  }

  return {
    refetch,
    filter,
    setFilter: setFilterAndResetPage,
    setFilterOnly: setFilterAndStore,
    localFilter,
    setLocalFilter,
    page,
    setPage,
    sort,
    setSort,
    textSearch,
    setTextSearch,
    loading,
    setLoading,
    handleSearch,
    handleReset,
    onChange,
    getColumnSearchProps,
    getColumnLocalSearchProps,
    setColumnSearchDrop,
    totalCount,
    setTotalCount,
    setAssetContentIds,
    renderTags,
    onExport,
    onExportWithCheckbox,
    variables: {
      ...variables,
      filter,
      sortBy: sort,
      limit: itemsPerPage,
      page,
    },
    setVariables,
    setExportId,
  }
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}
